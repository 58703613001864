import React from 'react';
import {IonGrid, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonReorder} from "@ionic/react";
import {removeCircle, reorderThreeOutline} from "ionicons/icons";
import {Link} from "react-router-dom";
import { DATA_URL } from '../../utils/constants';
import PortfolioItemRowEdit from './PortfolioItemRowEdit';

const apiURL = DATA_URL + '/quote/?format=json&s=';
let dataSrc;

export default class PortfolioItemEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      quoteData: []
    };
    this.ionSlider = React.createRef()
  }

  componentDidMount() {
    this.getData();
  }
  getData = () => {
    // clear any errors
    this.setState({
      error: null
    });
    // fetch the data
    if (this.props.symbol !== null) {
      dataSrc = apiURL + this.props.symbol + `&triangles=1&id=${this.props.id}&ticket=${this.props.ticket}`;
    }
    fetch(dataSrc)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error.');
        }
      })
      .then(data => {
        this.setState({
          quoteData: data[`${this.props.symbol}`],
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false
        });
      });
  }
  // open the slider to confirm delete
  deleteXButton = (e) => {
    e.preventDefault();
    this.ionSlider.current.open();
  }
  render() {
    return(
      <IonItemSliding ref={this.ionSlider}>
        <IonItem className={"portfolio-item portfolio-table"}>
          <Link to={"/portfolio/delete/" + this.props.symbol} onClick={this.deleteXButton} name={this.props.symbol}>
            <IonIcon className={"align_middle"} icon={removeCircle} slot={"start"} color={"danger"} size={"large"}/>
          </Link>
          <IonReorder slot={"end"}>
            <IonIcon icon={reorderThreeOutline}/>
          </IonReorder>
          <IonGrid>
            <PortfolioItemRowEdit
              symbol={this.props.symbol}
              name={this.state.quoteData.name}
              shortsymbol={this.state.quoteData.shortsymbol}
              last={this.state.quoteData.last}
              netchange={this.state.quoteData.netchange}
              pctchange={this.state.quoteData.pctchange}
              changeclass={this.state.quoteData.changeclass}
              triangles={this.state.quoteData.triangles}
            />
          </IonGrid>
        </IonItem>
        <IonItemOptions>
          <IonItemOption color="danger" onClick={this.props.clickHandler} id={this.props.symbol} data-pfid={this.props.pfid}>
              Delete
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    )
  }
}