import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonBackButton,
  IonIcon,
} from "@ionic/react";
import BlogPost from "../components/TradersBlog/BlogPost";
import { Link } from "react-router-dom";
import { search } from "ionicons/icons";

export default function BlogPostPage(props) {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/blog" />
          </IonButtons>
          <IonButtons slot="end">
            <Link to={"/search"}>
              <IonIcon
                icon={search}
                className={"search-button"}
                color={"light"}
              />
            </Link>
          </IonButtons>
          <IonTitle>Member's Blog</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <BlogPost id={props.id} ticket={props.ticket} postid={props.match.params.postid} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
