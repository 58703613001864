import React from "react";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonReorderGroup,
  IonRow,
  IonSpinner,
  withIonLifeCycle,
} from "@ionic/react";
import {
  addOutline,
  removeOutline,
  chevronBackOutline,
} from "ionicons/icons";
import PortfolioItemEdit from "./PortfolioItemEdit";
import PortfolioItemReorder from "./PortfolioItemReorder";
import { Link } from "react-router-dom";
import { DATA_URL } from "../../utils/constants";
import PortfolioTable from "./PortfolioTable";

const deleteSymbolURL = DATA_URL + "/clubportfolio/delete/?";
const reorderURL = DATA_URL + "/clubportfolio/edit/?id=";
class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      portfolio: {},
      deleteData: {},
      reorderData: {},
    };
  }
  // fetch data after component loaded, set up timer for data refresh

  ionViewWillEnter() {
    this.props.getPortfolio();
  }

  /* componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.portfolio !== prevProps.portfolio) {
      // this.props.getPortfolio();
    }
  }*/

  doReorder = (event) => {
    let symb = this.props.portfolio.symbols[event.detail.from].symbol;

    // send new order
    this.reorderSymbol(symb, event.detail.from, event.detail.to);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    event.detail.complete();
  };
  // reorder
  reorderSymbol = (symbol, oldPos, newPos) => {
    // clear any errors
    this.setState({
      error: null,
    });
    let dataSrc =
      reorderURL +
      this.props.id +
      "&ticket=" +
      this.props.ticket +
      "&s=" +
      symbol +
      "&o=" +
      oldPos +
      "&n=" +
      newPos;
    // fetch the data
    fetch(dataSrc)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then((data) => {
        this.setReorderData(data, symbol, oldPos, newPos);
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };
  // set portfolio reorder data, returned from JSON to state
  setReorderData = (data, symbol, oldPos, newPos) => {
    // copy of state
    let portfolioCopy = this.props.portfolio;

    // remove existing symbol position
    let toMove = portfolioCopy.symbols.splice(oldPos, 1);

    // currently just updating one sortorder property, leaving others out of sync
    toMove[0].sortorder = newPos + 1;

    // add it to new position
    portfolioCopy.symbols.splice(newPos, 0, toMove[0]);

    this.setState({
      reorderData: data,
      portfolio: portfolioCopy,
      isLoading: false,
    });
  };

  // clear reorder data
  clearReorderData = () => {
    this.setState({
      reorderData: {},
    });
  };
  // remove symbol
  removeSymbol = (symbol, pfid) => {
    // clear any errors
    this.setState({
      error: null,
    });
    let dataSrc = deleteSymbolURL + `d=${symbol}&id=${this.props.id}&ticket=${this.props.ticket}&pfid=${pfid}`;
    // fetch the data
    fetch(dataSrc)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then((data) => {
        this.setDeleteData(data, symbol);
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };  

  // set portfolio delete data, returned from JSON to state
  setDeleteData = (data, symbol) => {
    // remove symbol from state so portfolio will refresh
    let portfolioCopy = this.props.portfolio;

    const toDelete = portfolioCopy.symbols.findIndex(
      (element) => element.symbol === symbol
    );
    portfolioCopy.symbols.splice(toDelete, 1);
    this.setState({
      deleteData: data,
      portfolio: portfolioCopy,
      isLoading: false,
    });
  };

  // clear delete data
  clearDeleteData = () => {
    this.setState({
      deleteData: {},
    });
  };
  // 2-step delete, confirm, then remove symbol. Confirmation handled by UI, sliding item to the left.
  // 1. confirm delete with UI, then process delete:
  processDelete = (e) => {
    // 2. delete the symbol
    e.preventDefault();
    this.removeSymbol(e.currentTarget.id, e.target.dataset.pfid);
  };
  displaySymbols() {  
    // general error
    if (this.props.portfolio.status === 0) {
      return (
        <>
          <div className={"alert alert-danger"}>
            Sorry. You must login first.
            <Link to={"/login"}> Login</Link>
          </div>
        </>
      );
    }
    // portfolio object empty
    else if (Object.keys(this.props.portfolio).length === 0) {
      return (
        <div>
          <IonSpinner color={"primary"} name={"dots"} />
          <p>Loading Portfolio...</p>
        </div>
      );
    } else {
      // editing
      if (this.props.edit) {
        // error deleting symbol
        if (this.state.deleteData.status === 0) {
          return (
            <>
              <p className={"alert alert-danger"}>
                Error: {this.state.deleteData.error}
              </p>
              <p>
                Please return to your portfolio and try again, or contact us for
                support.
              </p>
              <IonButton
                routerLink={"/portfolio/" + this.props.name + '/' + this.props.pfid}
                onClick={this.clearDeleteData}
              >
                <IonIcon icon={chevronBackOutline} slot={"start"} /> Portfolio
              </IonButton>
            </>
          );
        }
        return (
          <>
            <IonGrid>
              <IonRow className={"ion-align-items-start"}>
                <IonCol size={4}>
                  <IonButton routerLink={"/portfolio/" + this.props.name + '/' + this.props.pfid} size={"small"}>
                    <IonIcon icon={chevronBackOutline} slot={"start"} />{" "}
                    Portfolio
                  </IonButton>
                </IonCol>
                <IonCol className={"ion-text-end"} size={8}>
                  {/* <IonButton routerLink={"/portfolio/reorder/" + this.props.name + '/' + this.props.pfid} size={"small"}>
                    Reorder <IonIcon icon={reorderThreeOutline} slot={"end"} />
                  </IonButton> */}
                  <IonButton routerLink={"/portfolio/search/" + this.props.name + '/' + this.props.pfid} size={"small"}>
                    Add <IonIcon icon={addOutline} slot={"end"} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            {this.props.portfolio.symbols.map((symbols) => {
              return symbols.pfid === this.props.pfid ? (
                <PortfolioItemEdit
                  key={symbols.symbol}
                  clickHandler={this.processDelete}
                  id={this.props.id}
                  ticket={this.props.ticket}
                  {...symbols}
                />
              ) : null;
            })}
          </>
        );
      }
      // reorder
      if (this.props.reorder) {
        // error reordering symbol
        if (this.state.reorderData.status === 0) {
          return (
            <>
              <p className={"alert alert-danger"}>
                Error: {this.state.reorderData.error}
              </p>
              <p>
                Please return to your portfolio and try again, or contact us for
                support.
              </p>
              <IonButton
                routerLink={"/portfolio/" + this.props.name + '/' + this.props.pfid}
                onClick={this.clearReorderData}
              >
                <IonIcon icon={chevronBackOutline} slot={"start"} /> Portfolio
              </IonButton>
            </>
          );
        }
        return (
          <>
            <IonGrid>
              <IonRow className={"ion-align-items-start"}>
                <IonCol size={4}>
                  <IonButton routerLink={"/portfolio/" + this.props.name + '/' + this.props.pfid} size={"small"}>
                    <IonIcon icon={chevronBackOutline} slot={"start"} />{" "}
                    Portfolio
                  </IonButton>
                </IonCol>
                <IonCol className={"ion-text-end"} size={8}>
                  <IonButton routerLink={"/portfolio/search/" + this.props.name + '/' + this.props.pfid} size={"small"}>
                    Add <IonIcon icon={addOutline} slot={"end"} />
                  </IonButton>
                  <IonButton routerLink={"/portfolio/edit/" + this.props.name + '/' + this.props.pfid} size={"small"}>
                    Delete <IonIcon icon={removeOutline} slot={"end"} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonReorderGroup disabled={false} onIonItemReorder={this.doReorder}>
              {this.props.portfolio.symbols.map((symbols) => (
                <PortfolioItemReorder
                  name={symbols.symbol}
                  key={symbols.symbol}
                  id={this.props.id}
                  ticket={this.props.ticket}
                  {...symbols}
                />
              ))}
            </IonReorderGroup>
          </>
        );
      }
      // return portfolio
      return (
        <>
          <IonRow className={"ion-align-items-start"}>
            <IonCol className={"ion-text-end"}>
              <IonButton routerLink={"/subportfolio"} size={"small"} className="ion-float-left">
                <IonIcon icon={chevronBackOutline} slot={"start"} /> Back
              </IonButton>
              {/* <IonButton routerLink={"/portfolio/reorder/" + this.props.name + '/' + this.props.pfid} size={"small"}>
                Reorder <IonIcon icon={reorderThreeOutline} slot={"end"} />
              </IonButton> */}
              <IonButton routerLink={"/portfolio/search/" + this.props.name + '/' + this.props.pfid} size={"small"}>
                Add <IonIcon icon={addOutline} slot={"end"} />
              </IonButton>
              <IonButton routerLink={"/portfolio/edit/" + this.props.name + '/' + this.props.pfid} size={"small"}>
                Delete <IonIcon icon={removeOutline} slot={"end"} />
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
            <div className="App">
              <PortfolioTable
                id={this.props.id}
                ticket={this.props.ticket}
                products={this.props.portfolio.symbols}
                name={this.props.name}
                pfid={this.props.pfid}
              />
            </div>
            </IonCol>
          </IonRow>
        </>
      );
    }
  }
  render() {
    return this.displaySymbols();
  }
}

export default withIonLifeCycle(Portfolio);
