import React from "react";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonReorderGroup,
  IonRow,
  IonSpinner,
  withIonLifeCycle,
} from "@ionic/react";
import {
  addOutline,
  removeOutline,
  reorderThreeOutline,
  chevronBackOutline,
  pencilOutline,
} from "ionicons/icons";
import { Link } from "react-router-dom";
import { DATA_URL } from "../../utils/constants";
import SubPortfolioItemEdit from "./SubPortfolioItemEdit";
import SubPortfolioItemReorder from "./SubPortfolioItemReorder";
import SubPortfolioItem from "./SubPortfolioItem";
import SubPortfolioItemRename from "./SubPortfolioItemRename";

const deleteURL = DATA_URL + "/clubportfolio/group/?id=";
const reorderURL = DATA_URL + "/clubportfolio/group/?id=";
const renameURL = DATA_URL + "/clubportfolio/group/?id=";
class SubPortfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      deleteData: {},
      reorderData: {},
      renameData: {},
    };
  }
  // fetch data after component loaded, set up timer for data refresh

  ionViewWillEnter() {
    this.props.getPortfolio();
  }

  /* componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.portfolio !== prevProps.portfolio) {
      // this.props.getPortfolio();
    }
  } */

  doReorder = (event) => {
    let pfid = Object.keys(this.props.portfolio.pfgroups[event.detail.from])[0];

    // send new order
    this.reorderPortfolio(pfid, event.detail.from, event.detail.to);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    event.detail.complete();
  };
  // reorder
  reorderPortfolio = (pfid, oldPos, newPos) => {
    // clear any errors
    this.setState({
      error: null,
      isLoading: true
    });
    let dataSrc =
      reorderURL +
      `${this.props.id}&ticket=${this.props.ticket}&mode=move&pfid=${pfid}&order=${newPos}`;
    // fetch the data
    fetch(dataSrc)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then((data) => {
        this.setReorderData(data);
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };
  // set portfolio reorder data, returned from JSON to state
  setReorderData = (data) => {
    this.props.portfolio.pfgroups = data.pfgroups;

    this.setState({
      reorderData: data,
      isLoading: false,
    });
  };

  // clear reorder data
  clearReorderData = () => {
    this.setState({
      reorderData: {},
    });
  };
  // remove portfolio
  removePortfolio = (pfid) => {
    // clear any errors
    this.setState({
      error: null,
      isLoading: true,
    });
    let dataSrc =
      deleteURL +
      `${this.props.id}&ticket=${this.props.ticket}&mode=delete&pfid=${pfid}`;
    // fetch the data
    fetch(dataSrc)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then((data) => {
        this.setDeleteData(data);
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };

  // set portfolio delete data, returned from JSON to state
  setDeleteData = (data) => {
    this.props.portfolio.pfgroups = data.pfgroups;

    this.setState({
      deleteData: data,
      isLoading: false,
    });
  };

  // clear delete data
  clearDeleteData = () => {
    this.setState({
      deleteData: {},
    });
  };

  // rename portfolio
  renamePortfolio = (pfid, pfname) => {
    // clear any errors
    this.setState({
      error: null,
      isLoading: true,
    });
    let dataSrc =
      renameURL +
      `${this.props.id}&ticket=${this.props.ticket}&mode=rename&pfid=${pfid}&pfname=${pfname}`;
    // fetch the data
    fetch(dataSrc)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then((data) => {
        this.setRenameData(data);
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };

  // set portfolio edit data, returned from JSON to state
  setRenameData = (data) => {
    this.props.portfolio.pfgroups = data.pfgroups;

    this.setState({
      renameData: data,
      isLoading: false,
    });
  };

  // clear edit data
  clearRenameData = () => {
    this.setState({
      renameData: {},
    });
  };

  processDelete = (e) => {
    e.preventDefault();
    this.removePortfolio(e.currentTarget.id);
  };

  processRename = (pfid, pfname) => {
    this.renamePortfolio(pfid, pfname);
  };

  displaySymbols() {
    // general error
    if (this.props.portfolio.status === 0) {
      return (
        <>
          <div className={"alert alert-danger"}>
            Sorry. You must login first.
            <Link to={"/login"}> Login</Link>
          </div>
        </>
      );
    }
    // portfolio object empty
    else if (Object.keys(this.props.portfolio).length === 0) {
      return (
        <div>
          <IonSpinner color={"primary"} name={"dots"} />
          <p>Loading Portfolio...</p>
        </div>
      );
    } else {
      // editing
      if (this.props.edit) {
        // error deleting symbol
        if (this.state.deleteData.status === 0) {
          return (
            <>
              <p className={"alert alert-danger"}>
                Error: {this.state.deleteData.error}
              </p>
              <p>
                Please return to your portfolio and try again, or contact us for
                support.
              </p>
              <IonButton
                routerLink={"/subportfolio"}
                onClick={this.clearDeleteData}
              >
                <IonIcon icon={chevronBackOutline} slot={"start"} /> Portfolio
              </IonButton>
            </>
          );
        }
        return (
          <>
            <IonGrid>
              <IonRow className={"ion-align-items-start"}>
                <IonCol size={3}>
                  <IonButton routerLink={"/subportfolio"} size={"small"} disabled={this.state.isLoading}>
                    <IonIcon icon={chevronBackOutline} slot={"start"} />{" "}
                    Portfolio
                  </IonButton>
                </IonCol>
                <IonCol className={"ion-text-end"} size={9}>
                  <IonButton
                    routerLink={"/subportfolio/reorder"}
                    size={"small"}
                    disabled={this.state.isLoading}
                  >
                    Reorder <IonIcon icon={reorderThreeOutline} slot={"end"} />
                  </IonButton>
                  <IonButton routerLink={"/subportfolio/add"} size={"small"} disabled={this.state.isLoading}>
                    Add <IonIcon icon={addOutline} slot={"end"} />
                  </IonButton>
                  <IonButton routerLink={"/subportfolio/rename"} size={"small"} disabled={this.state.isLoading}>
                    Edit <IonIcon icon={pencilOutline} slot={"end"} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            {this.props.portfolio.pfgroups &&
              this.props.portfolio.pfgroups.map((p) => {
                for (const [key, val] of Object.entries(p)) {
                  return (
                    <SubPortfolioItemEdit
                      key={key}
                      clickHandler={this.processDelete}
                      name={val}
                      pfid={key}
                    />
                  );
                }
                return true;
              })}
          </>
        );
      }
      // reorder
      if (this.props.reorder) {
        // error reordering symbol
        if (this.state.reorderData.status === 0) {
          return (
            <>
              <p className={"alert alert-danger"}>
                Error: {this.state.reorderData.error}
              </p>
              <p>
                Please return to your portfolio and try again, or contact us for
                support.
              </p>
              <IonButton
                routerLink={"/subportfolio"}
                onClick={this.clearReorderData}
              >
                <IonIcon icon={chevronBackOutline} slot={"start"} /> Portfolio
              </IonButton>
            </>
          );
        }
        return (
          <>
            <IonGrid>
              <IonRow className={"ion-align-items-start"}>
                <IonCol size={3}>
                  <IonButton routerLink={"/subportfolio"} size={"small"} disabled={this.state.isLoading}>
                    <IonIcon icon={chevronBackOutline} slot={"start"} />{" "}
                    Portfolio
                  </IonButton>
                </IonCol>
                <IonCol className={"ion-text-end"} size={9}>
                  <IonButton routerLink={"/subportfolio/add"} size={"small"} disabled={this.state.isLoading}>
                    Add <IonIcon icon={addOutline} slot={"end"} />
                  </IonButton>
                  <IonButton routerLink={"/subportfolio/rename"} size={"small"} disabled={this.state.isLoading}>
                    Edit <IonIcon icon={pencilOutline} slot={"end"} />
                  </IonButton>
                  <IonButton routerLink={"/subportfolio/edit"} size={"small"} disabled={this.state.isLoading}>
                    Delete <IonIcon icon={removeOutline} slot={"end"} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonReorderGroup disabled={false} onIonItemReorder={this.doReorder}>
              {this.props.portfolio.pfgroups &&
                this.props.portfolio.pfgroups.map((p) => {
                  for (const [key, val] of Object.entries(p)) {
                    return (
                      <SubPortfolioItemReorder
                        name={val}
                        key={key}
                        pfid={key}
                      />
                    );
                  }
                  return true;
                })}
            </IonReorderGroup>
          </>
        );
      }
      // editing
      if (this.props.rename) {
        // error deleting symbol
        if (this.state.renameData.status === 0) {
          return (
            <>
              <p className={"alert alert-danger"}>
                Error: {this.state.renameData.error}
              </p>
              <p>
                Please return to your portfolio and try again, or contact us for
                support.
              </p>
              <IonButton
                routerLink={"/subportfolio"}
                onClick={this.clearRenameData}
              >
                <IonIcon icon={chevronBackOutline} slot={"start"} /> Portfolio
              </IonButton>
            </>
          );
        }
        return (
          <>
            <IonGrid>
              <IonRow className={"ion-align-items-start"}>
                <IonCol size={3}>
                  <IonButton routerLink={"/subportfolio"} size={"small"} disabled={this.state.isLoading}>
                    <IonIcon icon={chevronBackOutline} slot={"start"} />{" "}
                    Portfolio
                  </IonButton>
                </IonCol>
                <IonCol className={"ion-text-end"} size={9}>
                  <IonButton
                    routerLink={"/subportfolio/reorder"}
                    size={"small"}
                    disabled={this.state.isLoading}
                  >
                    Reorder <IonIcon icon={reorderThreeOutline} slot={"end"} />
                  </IonButton>
                  <IonButton routerLink={"/subportfolio/add"} size={"small"} disabled={this.state.isLoading}>
                    Add <IonIcon icon={addOutline} slot={"end"} />
                  </IonButton>
                  <IonButton routerLink={"/subportfolio/edit"} size={"small"} disabled={this.state.isLoading}>
                    Delete <IonIcon icon={removeOutline} slot={"end"} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonRow>
              <IonCol>
                <div className={"portfolio-table"}>
                  <table
                    border="0"
                    cellPadding="0"
                    cellSpacing="0"
                    className="table table-condensed"
                  >
                    <tbody>
                      {this.props.portfolio.pfgroups &&
                        this.props.portfolio.pfgroups.map((p) => {
                          for (const [key, val] of Object.entries(p)) {
                            return (
                              <SubPortfolioItemRename
                                key={key}
                                renameHandler={(pfID, pfName) =>
                                  this.processRename(pfID, pfName)
                                }
                                name={val}
                                pfid={key}
                                isLoading={this.state.isLoading}
                              />
                            );
                          }
                          return true;
                        })}
                    </tbody>
                  </table>
                </div>
              </IonCol>
            </IonRow>
          </>
        );
      }
      // return portfolio
      return (
        <>
          <IonRow className={"ion-align-items-start"}>
            <IonCol className={"ion-text-end"}>
              <IonButton routerLink={"/subportfolio/reorder"} size={"small"}>
                Reorder <IonIcon icon={reorderThreeOutline} slot={"end"} />
              </IonButton>
              <IonButton routerLink={"/subportfolio/add"} size={"small"}>
                Add <IonIcon icon={addOutline} slot={"end"} />
              </IonButton>
              <IonButton routerLink={"/subportfolio/rename"} size={"small"}>
                Edit <IonIcon icon={pencilOutline} slot={"end"} />
              </IonButton>
              <IonButton routerLink={"/subportfolio/edit"} size={"small"}>
                Delete <IonIcon icon={removeOutline} slot={"end"} />
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className={"portfolio-table"}>
                <table
                  border="0"
                  cellPadding="0"
                  cellSpacing="0"
                  className="table table-condensed"
                >
                  <tbody>
                    {this.props.portfolio.pfgroups &&
                      this.props.portfolio.pfgroups.map((p) => {
                        for (const [key, val] of Object.entries(p)) {
                          return (
                            <SubPortfolioItem name={val} key={key} pfid={key} />
                          );
                        }
                        return true;
                      })}
                  </tbody>
                </table>
              </div>
            </IonCol>
          </IonRow>
        </>
      );
    }
  }
  render() {
    return this.displaySymbols();
  }
}

export default withIonLifeCycle(SubPortfolio);
