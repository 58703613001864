import React from "react";
import {
  IonSpinner,
  withIonLifeCycle,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonRow,
} from "@ionic/react";
import { DATA_URL } from "../../utils/constants";
import TopStocksItem from "./TopStocksItem";

let intervalID;
let limit = 10;
const dataSrc = DATA_URL + `/toplist/?`;

const topListOptions = {
  topstocks: "Top Stocks",
  topetfs: "Top ETFs",
  toppenny: "Top Penny Stocks",
  toptech: "Top Tech Stocks",
  topforex: "Top Forex",
  topoptions: "Top Options",
  topfutures: "Top Futures",
  topcst: "Top CST",
  crashstocks: "Crash Stocks",
};

class TopStocks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: "topstocks",
      symbols: [],
      description: "Top Stocks",
      isLoading: true,
      error: null,
      totalcount: 0,
      isLoadData: false,
      isPercent: true,
    };
    this.getData = this.getData.bind(this);
    this.setTimer = this.setTimer.bind(this);
  }

  // fetch data after component loaded, set up timer for data refresh
  ionViewWillEnter() {
    limit = 10;
    this.getData();
    this.setTimer();
  }

  ionViewWillLeave() {
    window.clearInterval(intervalID);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.list !== prevState.list) {
      // Reset timer and fetch data again if list changes
      window.clearInterval(intervalID);
      this.getData();
      this.setTimer();
    }
  }

  selectListChange() {
    this.setState({
      triangles: this.state.currencies,
    });
  }

  handleChange(e) {
    limit = 10;
    this.setState({
      list: e.target.value,
      description: topListOptions[e.target.value],
      isLoading: true,
    });
  }

  getData() {
    // clear any errors
    this.setState({
      error: null,
      // isLoading: true,
      totalcount: 0,
    });
    // fetch the data
    fetch(
      dataSrc +
        "limit=" +
        limit +
        "&triangles=1&list=" +
        this.state.list +
        "&id=" +
        this.props.id +
        "&ticket=" +
        this.props.ticket
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then((data) => {
        this.setState({
          list: data.list,
          symbols: data.symbols,
          description: data.description,
          isLoading: false,
          totalcount: data.totalcount,
          isLoadData: false,
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
          totalcount: 0,
          isLoadData: false,
        });
        // there is an error, try again after a defined interval
        window.clearInterval(intervalID);
        this.refreshAfterFailure();
      });
  }

  // refresh the data periodically (30 sec.)
  setTimer() {
    intervalID = window.setInterval(this.getData, 30000);
  }

  // there is an error, try again after 60 sec. (30 sec. timeout + 30 sec. timer)
  refreshAfterFailure() {
    // start the timer again
    window.setTimeout(this.setTimer, 30000);
  }

  showMoreStocks = (e) => {
    e.preventDefault();
    limit += 10;
    this.setState({ isLoadData: true });
    this.getData();
  };

  toggleChangeKey = () => {
    this.setState({
      isPercent: !this.state.isPercent
    });
  };

  // render function will be called again when state is set in getData()
  render() {
    return (
      <>
        <IonRow>
          <IonSelect
            className="top-lists-select pt-0 pb-0"
            value={this.state.list}
            interface={"action-sheet"}
            onIonChange={(e) => this.handleChange(e)}
          >
            {Object.keys(topListOptions).map((key) => {
              return (
                <IonSelectOption key={key} value={key}>
                  {topListOptions[key]}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </IonRow>
        <h2>{this.state.description} </h2>
        {this.state.isLoading && (
          <div>
            <IonSpinner color={"primary"} name={"dots"} />
            <p>Loading...</p>
          </div>
        )}
        {this.state.error && <p>{this.state.error.message}</p>}
        {!this.state.isLoading && this.state.symbols.length && (
          <div className={"portfolio-table"}>
            <table
              border="0"
              cellPadding="0"
              cellSpacing="0"
              className="table table-condensed"
            >
              <thead className="header-table">
                <tr>
                  <th>Symbol</th>
                  <th>Triangles</th>
                  <th className="ion-text-center">Last</th>
                  <th className="ion-text-center">Change</th>
                </tr>
              </thead>
              <tbody>
                {this.state.symbols.map((symbol) => {
                  symbol.isPercent = this.state.isPercent;
                  return (
                    <TopStocksItem
                      {...symbol}
                      key={symbol.symbol}
                      switchOfPricePercent={() => this.toggleChangeKey()}
                    />
                  );
                })}
              </tbody>
            </table>
            <form onSubmit={this.showMoreStocks}>
              <IonButton
                type={"submit"}
                size={"small"}
                expand={"block"}
                disabled={this.state.totalcount <= limit}
              >
                {this.state.isLoadData ? (
                  <IonSpinner name={"circles"} />
                ) : (
                  <span>
                    Show more (&nbsp;
                    {this.state.totalcount <= limit
                      ? `${this.state.totalcount} of ${this.state.totalcount}`
                      : `${limit} of ${this.state.totalcount}`}
                    &nbsp;)
                  </span>
                )}
              </IonButton>
            </form>
          </div>
        )}
      </>
    );
  }
}

export default withIonLifeCycle(TopStocks);
