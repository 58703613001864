import React from "react";
import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import BlogIndex from "../components/TradersBlog/BlogIndex";
import MarketPulse from "../components/MarketPulse/MarketPulse";
import { Link } from "react-router-dom";
import { search } from "ionicons/icons";

const titleStyle = {
  fontSize: "18px",
};
export default function BlogPage(props) {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={"start"}>
            <IonMenuButton onClick={props.toggleMenu} />
          </IonButtons>
          <IonButtons slot="end">
            <Link to={"/search"}>
              <IonIcon
                icon={search}
                className={"search-button"}
                color={"light"}
              />
            </Link>
          </IonButtons>
          <IonTitle>Member's Blog</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <MarketPulse id={props.id} ticket={props.ticket} />

              <IonRow>
                <IonCol>
                  <h1 style={titleStyle}>Member's Blog</h1>
                </IonCol>
              </IonRow>

              <BlogIndex id={props.id} ticket={props.ticket} numstories={12} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
