import React from "react";
import { IonSpinner, withIonLifeCycle } from "@ionic/react";
import QuoteTable from "./QuoteTable";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { DATA_URL } from "../../utils/constants";

// to split into two fundamental tables, really only works on a tablet or larger:
// const apiURL = DATA_URL + '/quote/?format=json&fund=1&splitfund=1&s=';
// single table:
const apiURL = DATA_URL + "/quote/?format=json&fund=1&s=";
let dataSrc, intervalID;

class Quote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      error: null,
    };
    this.getData = this.getData.bind(this);
    this.setTimer = this.setTimer.bind(this);
  }

  ionViewWillEnter() {
    this.getData();
    this.setTimer();
  }

  ionViewWillLeave() {
    window.clearInterval(intervalID);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.symbol !== this.props.symbol) {
      this.getData();
    }
  }

  getData() {
    // clear any errors
    this.setState({
      error: null,
    });
    // fetch the data
    if (this.props.symbol !== null) {
      dataSrc = apiURL + `${this.props.symbol}&id=${this.props.id}&ticket=${this.props.ticket}`;
    }
    fetch(dataSrc)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then((data) => {
        this.setState({
          data: data[`${this.props.symbol}`],
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
        // there is an error, try again after 60 sec.
        window.clearInterval(intervalID);
        this.refreshAfterFailure();
      });
  }

  setTimer() {
    intervalID = window.setInterval(this.getData, 30000);
  }

  // there is an error, try again after 60 sec. (30 sec. timeout + 30 sec. timer)
  refreshAfterFailure() {
    // start the timer again
    window.setTimeout(this.setTimer, 30000);
  }

  render() {
    if (this.state.error) {
      return <p>{this.state.error.message}</p>;
    }
    if (this.state.isLoading) {
      return (
        <div>
          <IonSpinner color={"primary"} name={"dots"} />
          <p>Loading...</p>
        </div>
      );
    }
    return (
      <IonGrid>
        <IonRow>
          <IonCol>
            <QuoteTable fundamental={this.state.data.fundamental} />
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  }
}

export default withIonLifeCycle(Quote);
