import React from "react";
import {
  IonApp,
  IonRouterOutlet,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonMenu,
  IonButton,
  withIonLifeCycle,
  NavContext,
} from "@ionic/react";
import { menuController } from "@ionic/core";
import { IonReactRouter } from "@ionic/react-router";
import { Route, Redirect } from "react-router-dom";
import { menu, home, barChart, list, eye } from "ionicons/icons";
import { Plugins } from "@capacitor/core";
import PortfolioSearchPage from "./pages/PortfolioSearchPage";
import PortfolioAddPage from "./pages/PortfolioAddPage";

// MarketClub styles & Bootstrap
import "./scss/main.scss";

/* Ionic: Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Ionic: Basic CSS for apps built with Ionic */
// Reboot with Bootstrap, but some specific ionic classes in here
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Ionic: Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables & Ionic overrides */
import "./scss/theme/_variables.css";
import "./scss/theme/_ionic.scss";

/* mobile overrides */
import "./scss/theme/_overrides.scss";

import HomePage from "./pages/HomePage";
import ChartPage from "./pages/ChartPage";
import TopLists from "./pages/TopLists";
import AlertPage from "./pages/AlertPage";
// import ScanPage from "./pages/ScanPage";
import SupportPage from "./pages/SupportPage";
import SearchPage from "./pages/SearchPage";
import ForgotPasswordForm from "./components/Login/ForgotPasswordForm";
import BlogPage from "./pages/BlogPage";
import BlogPostPage from "./pages/BlogPostPage";
import Logout from "./components/Logout/Logout";
import Login from "./components/Login/Login";
import PortfolioPage from "./pages/PortfolioPage";
import { DATA_URL } from "./utils/constants";
import SubPortfolioPage from "./pages/SubPortfolioPage";
import SubPortfolioAddPage from "./pages/SubPortfolioAddPage";

window.menuController = menuController;
const { Storage } = Plugins;

const APP_VERSION = "1.0";

const getPortfolioURL = DATA_URL + "/clubportfolio/?";
const noticeApi = DATA_URL + "/clubportfolio/notice/?";
class App extends React.Component {
  static contextType = NavContext;
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoading: true,
      // local storage
      id: null,
      ticket: null,
      email: "",
      portfolio: {},
      isAuthenticated: false,
      userInfo: { firstname: "", lastname: "", email: "" },
      memberMessage: {},
    };
  }

  /**
   * Retrieve user from local storage and add it to state.
   */
  componentDidMount() {
    this.getLocalStorage().then((user) => {
      if (user) {
        this.setUserData(user);
      }
    });
  }

  /**
   * Get user object from local storage.
   * Return the value if it's not null.
   * @returns {Promise<*>}
   */
  getLocalStorage = async () => {
    let userObj = await Storage.get({ key: "user" });
    let user = await JSON.parse(userObj.value);

    if (user !== null) {
      this.setState({
        isAuthenticated: true,
      });
      return user;
    } else {
      this.setState({
        isLoading: false,
        isAuthenticated: false,
      });
    }
  };
  /**
   * Set user data to local storage.
   * @param data
   * @returns {Promise<void>}
   */
  setLocalStorage = async (data) => {
    if (data) {
      await Storage.set({
        key: "user",
        value: JSON.stringify({
          id: data.id,
          ticket: data.ticket,
          email: data.email,
        }),
      });
      this.context.navigate("/home", "back");
    }
  };
  /**
   * Set user data to state.
   * @param user
   */
  setUserData = (user) => {
    if (user !== undefined) {
      this.setState(
        {
          id: user.id,
          ticket: user.ticket,
          email: user.email,
        },
        this.initialGetPortfolio
      );
    }
  };

  /**
   * Get portfolio obj if logged in
   */
  initialGetPortfolio = async () => {
    await this.loginCheck();
  };

  /**
   * Check ID & ticket, API calls for portfolio and member info
   */
  loginCheck = () => {
    if (this.state.id !== null && this.state.ticket !== null) {
      this.getPortfolio();
      this.getMemberInfo();
    }
  };

  /**
   * Get portfolio
   * - Used on chart pages for add to/in portfolio buttons.
   */
  getPortfolio = () => {
    console.log("App > getPortfolio()");
    // clear any errors
    this.setState({
      error: null,
    });
    let dataSrc =
      getPortfolioURL + "id=" + this.state.id + "&ticket=" + this.state.ticket;
    // fetch the data
    fetch(dataSrc)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then((data) => {
        this.setState({ portfolio: data });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  };

  /**
   * Get member messages and user info (firstname, lastname, email)
   */
  getMemberInfo() {
    // clear any errors
    this.setState({
      error: null,
      isLoading: true,
    });

    // fetch the data
    fetch(noticeApi + `id=${this.state.id}&ticket=${this.state.ticket}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then((data) => {
        this.setState({
          userInfo: data.userinfo,
          memberMessage: data.memberMessage,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  /**
   * Toggle sidebar menu
   * @returns {Promise<void>}
   */
  toggleMenu = async () => {
    await menuController.toggle("sideMenu");
  };

  isLoggedIn = () => {
    return this.state.id !== null && this.state.ticket !== null;
  };

  /**
   * Display user's name on homepage
   * @returns {JSX.Element}
   */
  displayUser = () => {
    if (this.state.email !== "" && this.state.id) {
      return (
        <IonItem>
          <small>Hi&nbsp;</small>
          <small className="greeting-user">
            {this.state.userInfo.firstname} {this.state.userInfo.lastname}!
          </small>
        </IonItem>
      );
    }
  };

  /**
   * Return Log In or Log Out button in sidebar menu
   * @returns {JSX.Element}
   */
  displayLogoutButton = () => {
    if (this.state.id && this.state.ticket) {
      return (
        <IonItem onClick={this.toggleMenu} routerLink={"/logout"}>
          Log Out
        </IonItem>
      );
    } else {
      return (
        <IonItem onClick={this.toggleMenu} routerLink={"/login"}>
          Log In
        </IonItem>
      );
    }
  };

  /**
   * Logout: clear app data and state
   */
  logout = () => {
    Storage.clear();
    this.setState({
      error: null,
      isLoading: false,
      id: null,
      ticket: null,
      email: "",
      isAuthenticated: false,
      userInfo: { firstname: "", lastname: "", email: "" },
      memberMessage: {},
    });
  };

  render() {
    return (
      <IonApp>
        <IonReactRouter>
          <IonMenu side="start" menuId="sideMenu" contentId={"main"}>
            <IonHeader>
              <IonToolbar>
                <IonTitle>More</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonList>
                {this.displayUser()}
                <IonItem onClick={this.toggleMenu} routerLink={"/alerts"}>
                  Alerts
                </IonItem>
                <IonItem onClick={this.toggleMenu} routerLink={"/blog"}>
                  Member's Blog
                </IonItem>
                <IonItem onClick={this.toggleMenu} routerLink={"/search"}>
                  Search
                </IonItem>
                <IonItem
                  href={
                    "https://club.ino.com/join/login.html?id=" +
                    this.state.id +
                    "&ticket=" +
                    this.state.ticket +
                    "&destination=/members/blog/marketclub-help-section/"
                  }
                >
                  Help
                </IonItem>
                <IonItem
                  href={
                    "https://club.ino.com/join/login.html?id=" +
                    this.state.id +
                    "&ticket=" +
                    this.state.ticket +
                    "&destination=/members/account/"
                  }
                >
                  My Account
                </IonItem>
                <IonItem onClick={this.toggleMenu} routerLink={"/support"}>
                  Support Team
                </IonItem>
                <IonItem href={"https://club.ino.com/join/rules.html"}>
                  User Agreement
                </IonItem>
                <IonItem href={"https://club.ino.com/join/privacy.html"}>
                  Privacy Policy
                </IonItem>
                {this.displayLogoutButton()}
              </IonList>
              <IonItem>
                <small className={"version"}>
                  {"MarketClub Version " + APP_VERSION}
                </small>
              </IonItem>
            </IonContent>
          </IonMenu>
          <IonContent id={"main"}>
            {this.state.isAuthenticated ? (
              <IonTabs>
                <IonRouterOutlet>
                  <Route
                    exact
                    path="/home"
                    render={(props) => {
                      return (
                        <HomePage
                          id={this.state.id}
                          ticket={this.state.ticket}
                          portfolio={this.state.portfolio}
                          email={this.state.email}
                          userInfo={this.state.userInfo}
                          memberMessage={this.state.memberMessage}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Redirect exact from="/" to="/home" />
                  <Route
                    exact
                    path="/portfolio/add/:symbol/:name/:pfid"
                    render={(props) => {
                      return (
                        <PortfolioAddPage
                          id={this.state.id}
                          ticket={this.state.ticket}
                          isLoggedIn={this.isLoggedIn}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/portfolio/search/:name/:pfid"
                    render={(props) => {
                      return (
                        <PortfolioSearchPage
                          ticket={this.state.ticket}
                          id={this.state.id}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/subportfolio/add"
                    render={(props) => {
                      return (
                        <SubPortfolioAddPage
                          id={this.state.id}
                          ticket={this.state.ticket}
                          isLoggedIn={this.isLoggedIn}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/portfolio/edit/:name/:pfid"
                    render={(props) => {
                      return (
                        <PortfolioPage
                          edit={true}
                          id={this.state.id}
                          ticket={this.state.ticket}
                          portfolio={this.state.portfolio}
                          getPortfolio={this.getPortfolio}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/portfolio/reorder/:name/:pfid"
                    render={(props) => {
                      return (
                        <PortfolioPage
                          reorder={true}
                          id={this.state.id}
                          ticket={this.state.ticket}
                          portfolio={this.state.portfolio}
                          getPortfolio={this.getPortfolio}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/portfolio/:name/:pfid"
                    render={(props) => {
                      return (
                        <PortfolioPage
                          id={this.state.id}
                          ticket={this.state.ticket}
                          toggleMenu={this.toggleMenu}
                          portfolio={this.state.portfolio}
                          getPortfolio={this.getPortfolio}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/subportfolio/edit"
                    render={(props) => {
                      return (
                        <SubPortfolioPage
                          edit={true}
                          id={this.state.id}
                          ticket={this.state.ticket}
                          portfolio={this.state.portfolio}
                          getPortfolio={this.getPortfolio}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/subportfolio/rename"
                    render={(props) => {
                      return (
                        <SubPortfolioPage
                          rename={true}
                          id={this.state.id}
                          ticket={this.state.ticket}
                          portfolio={this.state.portfolio}
                          getPortfolio={this.getPortfolio}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/subportfolio/reorder"
                    render={(props) => {
                      return (
                        <SubPortfolioPage
                          reorder={true}
                          id={this.state.id}
                          ticket={this.state.ticket}
                          portfolio={this.state.portfolio}
                          getPortfolio={this.getPortfolio}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/subportfolio"
                    render={(props) => {
                      return (
                        <SubPortfolioPage
                          id={this.state.id}
                          ticket={this.state.ticket}
                          toggleMenu={this.toggleMenu}
                          portfolio={this.state.portfolio}
                          getPortfolio={this.getPortfolio}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/chart/:symbol"
                    render={(props) => {
                      return (
                        <ChartPage
                          ticket={this.state.ticket}
                          id={this.state.id}
                          portfolio={this.state.portfolio}
                          loginCheck={this.loginCheck}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path={"/lists"}
                    render={(props) => {
                      return (
                        <TopLists
                          ticket={this.state.ticket}
                          id={this.state.id}
                          {...props}
                        />
                      );
                    }}
                  />
                  {/*<Route*/}
                  {/*  exact*/}
                  {/*  path={"/scan"}*/}
                  {/*  render={(props) => {*/}
                  {/*    return this.state.isAuthenticated ? (*/}
                  {/*      <ScanPage*/}
                  {/*        ticket={this.state.ticket}*/}
                  {/*        id={this.state.id}*/}
                  {/*        {...props}*/}
                  {/*      />*/}
                  {/*    ) : (*/}
                  {/*      <Redirect to={"/login"} />*/}
                  {/*    );*/}
                  {/*  }}*/}
                  {/*/>*/}
                  <Route
                    exact
                    path={"/alerts"}
                    render={(props) => {
                      return (
                        <AlertPage
                          id={this.state.id}
                          ticket={this.state.ticket}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path={"/support"}
                    render={(props) => {
                      return <SupportPage {...props} />;
                    }}
                  />
                  <Route
                    exact
                    path="/search"
                    render={(props) => {
                      return (
                        <SearchPage
                          ticket={this.state.ticket}
                          id={this.state.id}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/blog"
                    render={(props) => {
                      return (
                        <BlogPage
                          toggleMenu={this.toggleMenu}
                          ticket={this.state.ticket}
                          id={this.state.id}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/:tab(blog)/:postid"
                    render={(props) => {
                      return (
                        <BlogPostPage
                          id={this.state.id}
                          ticket={this.state.ticket}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path={"/login"}
                    render={(props) => {
                      return !this.state.isAuthenticated ? (
                        <Login setLocalStorage={this.setLocalStorage} />
                      ) : (
                        <Redirect
                          to={"/home"}
                          id={this.state.id}
                          ticket={this.state.ticket}
                          portfolio={this.state.portfolio}
                          email={this.state.email}
                          userInfo={this.state.userInfo}
                          memberMessage={this.state.memberMessage}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path={"/login/reset-password"}
                    render={(props) => {
                      return !this.state.isAuthenticated ? (
                        <ForgotPasswordForm {...props} />
                      ) : (
                        <Redirect
                          to={"/home"}
                          id={this.state.id}
                          ticket={this.state.ticket}
                          portfolio={this.state.portfolio}
                          email={this.state.email}
                          userInfo={this.state.userInfo}
                          memberMessage={this.state.memberMessage}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/logout"
                    render={() => <Logout logout={this.logout} />}
                  />
                </IonRouterOutlet>

                <IonTabBar slot="bottom">
                  <IonTabButton tab="home" href={"/home"}>
                    <IonIcon icon={home} />
                    <IonLabel>Home</IonLabel>
                  </IonTabButton>
                  <IonTabButton tab="portfolio" href={"/subportfolio"}>
                    <IonIcon icon={barChart} />
                    <IonLabel>Portfolio</IonLabel>
                  </IonTabButton>
                  <IonTabButton tab="lists" href={"/lists"}>
                    <IonIcon icon={list} />
                    <IonLabel>Top Lists</IonLabel>
                  </IonTabButton>
                  {/*<IonTabButton tab="scan" href={"/scan"}>*/}
                  {/*  <IonIcon icon={eye} />*/}
                  {/*  <IonLabel>Scan</IonLabel>*/}
                  {/*</IonTabButton>*/}
                  <IonTabButton tab="more" className={"moreBtn"}>
                    <IonButton
                      fill={"clear"}
                      onClick={this.toggleMenu}
                      className={"moreInnerBtn"}
                    >
                      <div className={"moreWrapper"}>
                        <IonIcon
                          icon={menu}
                          size={"large"}
                          className={"moreIcon"}
                        />
                        <IonLabel className={"moreLabel"}>More</IonLabel>
                      </div>
                    </IonButton>
                  </IonTabButton>
                </IonTabBar>
              </IonTabs>
            ) : (
              <IonContent id={"main"}>
                <IonRouterOutlet>
                  <Route
                    exact
                    path="/home"
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Redirect exact from="/" to="/home" />
                  <Route
                    exact
                    path="/portfolio/add/:symbol/:name/:pfid"
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Route
                    exact
                    path="/portfolio/search/:name/:pfid"
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Route
                    exact
                    path="/subportfolio/add"
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Route
                    exact
                    path="/portfolio/edit/:name/:pfid"
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Route
                    exact
                    path="/portfolio/reorder/:name/:pfid"
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Route
                    exact
                    path="/portfolio/:name/:pfid"
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Route
                    exact
                    path="/subportfolio/edit"
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Route
                    exact
                    path="/subportfolio/rename"
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Route
                    exact
                    path="/subportfolio/reorder"
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Route
                    exact
                    path="/subportfolio"
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Route
                    exact
                    path="/chart/:symbol"
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Route
                    exact
                    path={"/lists"}
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  {/*<Route*/}
                  {/*  exact*/}
                  {/*  path={"/scan"}*/}
                  {/*  render={(props) => {*/}
                  {/*    return this.state.isAuthenticated ? (*/}
                  {/*      <ScanPage*/}
                  {/*        ticket={this.state.ticket}*/}
                  {/*        id={this.state.id}*/}
                  {/*        {...props}*/}
                  {/*      />*/}
                  {/*    ) : (*/}
                  {/*      <Redirect to={"/login"} />*/}
                  {/*    );*/}
                  {/*  }}*/}
                  {/*/>*/}
                  <Route
                    exact
                    path={"/alerts"}
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Route
                    exact
                    path={"/support"}
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Route
                    exact
                    path="/search"
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Route
                    exact
                    path="/blog"
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Route
                    exact
                    path="/:tab(blog)/:postid"
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Route
                    exact
                    path={"/login"}
                    render={(props) => {
                      return <Login setLocalStorage={this.setLocalStorage} />;
                    }}
                  />
                  <Route
                    exact
                    path={"/login/reset-password"}
                    render={(props) => {
                      return <ForgotPasswordForm {...props} />;
                    }}
                  />
                  <Route
                    exact
                    path="/logout"
                    render={() => <Logout logout={this.logout} />}
                  />
                </IonRouterOutlet>
              </IonContent>
            )}
          </IonContent>
        </IonReactRouter>
      </IonApp>
    );
  }
}

export default withIonLifeCycle(App);
