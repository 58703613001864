import React from "react";
import { Link } from "react-router-dom";
import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { search } from "ionicons/icons";
import MarketPulse from "../components/MarketPulse/MarketPulse";
import SubPortfolio from "../components/Portfolio/SubPortfolio";

class SubPortfolioPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot={"start"}>
              <IonMenuButton onClick={this.props.toggleMenu} />
            </IonButtons>
            <IonButtons slot="end">
              <Link to={"/search"}>
                <IonIcon
                  icon={search}
                  className={"search-button"}
                  color={"light"}
                />
              </Link>
            </IonButtons>
            <IonTitle>Portfolio</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <MarketPulse id={this.props.id} ticket={this.props.ticket} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <SubPortfolio
                  id={this.props.id}
                  ticket={this.props.ticket}
                  edit={this.props.edit}
                  reorder={this.props.reorder}
                  rename={this.props.rename}
                  portfolio={this.props.portfolio}
                  getPortfolio={this.props.getPortfolio}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
}

export default SubPortfolioPage;
