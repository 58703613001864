import React from 'react';

const Post = props => (
  <div className="blog-entry">
    <h1>
      {props.title}
    </h1>
    <div className="blog-meta">
      <p className="date">{props.formatdate}</p>
      <p className="author">{props.poster}</p>
    </div>

    <div className="blog-content">
      <div dangerouslySetInnerHTML={{
      __html: props.content
    }}></div>
    </div>
  </div>
);

export default Post;