import React from "react";
import CSSTransition from "react-transition-group/CSSTransition";
import { Link } from "react-router-dom";
import { IonSlide } from "@ionic/react";

// market pulse individual component
class Market extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priceChange: false,
    };
    this.handleEntered = this.handleEntered.bind(this);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.price !== prevProps.price) {
      this.setState({
        priceChange: true,
      });
    }
  }
  handleEntered() {
    this.setState({
      priceChange: false,
    });
  }
  render() {
    return (
      <IonSlide>
        <dl className="market-pulse__market" id={"index" + this.props.id}>
          <dt>
            <Link to={"/chart/" + this.props.symbol}>{this.props.label}</Link>
          </dt>
          <dd className={this.props.class}>
            <CSSTransition
              timeout={700}
              classNames="market-change"
              in={this.state.priceChange}
              onEntered={this.handleEntered}
            >
              <span>{this.props.price}</span>
            </CSSTransition>
          </dd>
          <dd className={this.props.class}>
            <CSSTransition
              timeout={700}
              classNames="market-change"
              in={this.state.priceChange}
            >
              <span> {this.props.percentchange}%</span>
            </CSSTransition>
          </dd>
        </dl>
      </IonSlide>
    );
  }
}

export default Market;
