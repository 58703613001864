import React from "react";
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonReorder,
  IonRow,
} from "@ionic/react";
import { reorderThreeOutline } from "ionicons/icons";

export default class SubPortfolioItemReorder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
    };
    this.ionReorder = React.createRef();
  }

  render() {
    return (
      <IonItem className={"portfolio-item"}>
        <IonGrid>
          <IonRow className={"ion-align-items-center"}>
            <IonCol>{this.props.name}</IonCol>
          </IonRow>
        </IonGrid>
        <IonReorder slot={"end"} name={this.props.pfid}>
          <IonIcon icon={reorderThreeOutline} size={"large"} />
        </IonReorder>
      </IonItem>
    );
  }
}
