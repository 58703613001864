import React from "react";
import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Link } from "react-router-dom";
import { search } from "ionicons/icons";
import PortfolioAdd from "../components/Portfolio/PortfolioAdd";
import MarketPulse from "../components/MarketPulse/MarketPulse";

class PortfolioAddPage extends React.Component {
  renderPage = () => {
    // general error
    if (this.props.error) {
      return (
        <>
          <p className={"alert alert-danger"}>
            Error: {this.props.error.message}
          </p>
          <p>Please try again, or contact us for support.</p>
          <ion-router-link href="https://www.ino.com/help/email.html">
            {" "}
            Contact Us
          </ion-router-link>
        </>
      );
    }
    // loading data
    if (this.props.isLoading) {
      return (
        <div>
          <IonSpinner color={"primary"} name={"dots"} />
          <p>Loading...</p>
        </div>
      );
    }
    return (
      <PortfolioAdd
        id={this.props.id}
        ticket={this.props.ticket}
        symbol={this.props.match.params.symbol}
        name={this.props.match.params.name}
        pfid={this.props.match.params.pfid}
        isLoggedIn={this.props.isLoggedIn}
      />
    );
  };

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/portfolio" />
            </IonButtons>
            <IonButtons slot="end">
              <Link to={"/search"}>
                <IonIcon
                  icon={search}
                  className={"search-button"}
                  color={"light"}
                />
              </Link>
            </IonButtons>
            <IonTitle>Add Symbol</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <MarketPulse id={this.props.id} ticket={this.props.ticket} />
              </IonCol>
              <IonCol>{this.renderPage()}</IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
}

export default PortfolioAddPage;
