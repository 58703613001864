import React from "react";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonRow,
} from "@ionic/react";
import MarketPulse from "../components/MarketPulse/MarketPulse";
import SubPortfolioAdd from "../components/Portfolio/SubPortfolioAdd";

class SubPortfolioAddPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/subportfolio" />
            </IonButtons>
            <IonTitle>Add Portfolio</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCol>
            <MarketPulse id={this.props.id} ticket={this.props.ticket} />
          </IonCol>
          <IonRow>
            <IonCol className={"px-3"}>
              <SubPortfolioAdd
                id={this.props.id}
                ticket={this.props.ticket}
                isLoggedIn={this.props.isLoggedIn}
              />
            </IonCol>
          </IonRow>
        </IonContent>
      </IonPage>
    );
  }
}

export default SubPortfolioAddPage;
