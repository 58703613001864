import React from "react";

function TriIndicator(props) {
  const triangle = props.triangle;
  const symbol = props.symbol;
  const text = props.text;

  if (triangle === "up") {
    return (
      <>
        <div
          className={`triangle triangle-${triangle}`}
          title={symbol + " trading up"}
        ></div>
        {text && <span className="text">{text}</span>}
      </>
    );
  }
  return (
    <>
      <div
        className={`triangle triangle-${triangle}`}
        title={symbol + " trading down"}
      ></div>
      {text && <span className="text">{text}</span>}
    </>
  );
}

export default TriIndicator;
