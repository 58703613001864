import React from "react";
import { IonSpinner, withIonLifeCycle } from "@ionic/react";
import AlertTableRow from "./AlertTableRow";
import { DATA_URL } from "../../utils/constants";

const alertsListingDataSrc =
  DATA_URL + "/clubportfolio/scans/notifications/?id=";

let intervalID;

class AlertsListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alerts: [],
      isLoading: true,
      error: null,
    };
    this.getAlerts = this.getAlerts.bind(this);
    this.setTimer = this.setTimer.bind(this);
  }

  ionViewWillEnter() {
    this.getAlerts();
    this.setTimer();
  }

  getAlerts() {
    // clear any errors
    this.setState({
      error: null,
    });
    // fetch the data
    fetch(alertsListingDataSrc + `${this.props.id}&ticket=${this.props.ticket}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then(async (data) => {
        let alertsData = [];
        if (Object.keys(data.scans).length !== 0) {
          for (const key of Object.keys(data.scans)) {
            alertsData.push(data.scans[key]);
          }
        }
        this.setState({
          alerts: alertsData,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
        // there is an error, try again after a defined interval
        window.clearInterval(intervalID);
        this.refreshAfterFailure();
      });
  }

  // refresh the data periodically (30 sec.)
  setTimer() {
    intervalID = window.setInterval(this.getAlerts, 30000);
  }

  // there is an error, try again after 60 sec. (30 sec. timeout + 30 sec. timer)
  refreshAfterFailure() {
    // start the timer again
    window.setTimeout(this.setTimer, 30000);
  }

  // render function will be called again when state is set in getData()
  render() {
    if (this.state.error) {
      return <p>{this.state.error.message}</p>;
    }
    if (this.state.isLoading) {
      return (
        <div>
          <IonSpinner color={"primary"} name={"dots"} />
          <p>Loading...</p>
        </div>
      );
    }

    return (
      <>
        <h2>Alerts</h2>

        {!this.state.error && this.state.alerts.length ? (
          <div className={"alerts-table"}>
            <table
              border="0"
              cellPadding="0"
              cellSpacing="0"
              className="alerts-table"
            >
              <tbody>
                {this.state.alerts.map((alert) => (
                  <AlertTableRow {...alert} key={alert.rowid} />
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>There are no recent alerts.</div>
        )}
      </>
    );
  }
}

export default withIonLifeCycle(AlertsListing);
