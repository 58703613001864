import React from 'react';
import {IonGrid, IonIcon, IonItem, IonReorder} from "@ionic/react";
import PortfolioItemRowEdit from "./PortfolioItemRowEdit";
import {reorderThreeOutline} from "ionicons/icons";
import {DATA_URL} from "../../utils/constants";

const apiURL = DATA_URL + '/quote/?format=json&s=';
let dataSrc;

export default class PortfolioItemReorder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      quoteData: []
    };
    this.ionReorder = React.createRef()
  }
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    // clear any errors
    this.setState({
      error: null
    });
    // fetch the data
    if (this.props.symbol !== null) {
      dataSrc = apiURL + this.props.symbol + `&id=${this.props.id}&ticket=${this.props.ticket}`;;
    }
    fetch(dataSrc)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error.');
        }
      })
      .then(data => {
        this.setState({
          quoteData: data[`${this.props.symbol}`],
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false
        });
      });
  }
  render() {
    return(
      <IonItem className={"portfolio-item"}>
        <IonGrid>
          <PortfolioItemRowEdit
            symbol={this.props.symbol}
            name={this.state.quoteData.name}
            shortsymbol={this.state.quoteData.shortsymbol}
            last={this.state.quoteData.last}
            netchange={this.state.quoteData.netchange}
            pctchange={this.state.quoteData.pctchange}
            changeclass={this.state.quoteData.changeclass}
          />
        </IonGrid>
        <IonReorder slot={"end"} name={this.props.symbol}>
          <IonIcon icon={reorderThreeOutline} size={"large"}/>
        </IonReorder>
      </IonItem>
    )
  }
}