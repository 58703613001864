import React from "react";
import { IonSpinner, withIonLifeCycle, IonButton } from "@ionic/react";
import { DATA_URL } from "../../utils/constants";
import { Link } from "react-router-dom";

const alertsURL = DATA_URL + "/clubportfolio/scans/?";
let intervalID;
let limit = 10;

class PortfolioAlerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scansymbols: [],
      scandata: {},
      isLoading: true,
      error: null,
      totalcount: 0,
      isLoadData: false,
    };
    this.getData = this.getData.bind(this);
    this.setTimer = this.setTimer.bind(this);
  }
  // fetch data after component loaded, set up timer for data refresh
  ionViewWillEnter() {
    limit = 10;
    this.getData();
    this.setTimer();
  }
  ionViewWillLeave() {
    window.clearInterval(intervalID);
  }

  getData() {
    // clear any errors
    this.setState({
      error: null,
      totalcount: 0,
      isLoadData: true,
    });
    // fetch portfolio alerts
    fetch(
      alertsURL +
        "id=" +
        this.props.id +
        "&ticket=" +
        this.props.ticket +
        "&scantype=alerts&limit=" +
        limit
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then((data) => {
        this.setState({
          scansymbols: data.scansymbols,
          scandata: data.scandata,
          isLoading: false,
          totalcount: data.totalcount,
          isLoadData: false,
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
          totalcount: 0,
          isLoadData: false,
        });
        // there is an error, try again after a defined interval
        window.clearInterval(intervalID);
        this.refreshAfterFailure();
      });
  }

  showMoreAlerts = (e) => {
    e.preventDefault();
    limit += 10;
    this.getData();
  };

  // refresh the data periodically (30 sec.)
  setTimer() {
    intervalID = window.setInterval(this.getData, 30000);
  }
  // there is an error, try again after 60 sec. (30 sec. timeout + 30 sec. timer)
  refreshAfterFailure() {
    // start the timer again
    window.setTimeout(this.setTimer, 30000);
  }

  // render function will be called again when state is set in getData()
  render() {
    if (this.state.error) {
      return <p>{this.state.error.message}</p>;
    }
    if (this.state.isLoading) {
      return (
        <div>
          <IonSpinner color={"primary"} name={"dots"} />
          <p>Loading...</p>
        </div>
      );
    }
    return (
      <div>
        {this.state.scansymbols && this.state.scansymbols.length ? (
          <div>
            <div className={"portfolio-alerts"}>
              <ul>
                {this.state.scansymbols.map((scansymbol) => {
                  let scans = Object.values(
                    this.state.scandata[scansymbol].signal
                  );
                  return (
                    <li key={scansymbol}>
                      <b>
                        <Link to={"/chart/" + scansymbol}>
                          {this.state.scandata[scansymbol].shortsymbol}:{" "}
                        </Link>
                      </b>
                      <span key={scansymbol}> {scans.join(", ")}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
            <form
              onSubmit={this.showMoreAlerts}
              className="ion-text-center mt-2"
            >
              <IonButton
                type={"submit"}
                size={"small"}
                expand={"block"}
                disabled={this.state.totalcount <= limit}
              >
                {this.state.isLoadData ? (
                  <IonSpinner name={"circles"} />
                ) : (
                  <span>
                    Show more (&nbsp;
                    {this.state.totalcount <= limit
                      ? `${this.state.totalcount} of ${this.state.totalcount}`
                      : `${limit} of ${this.state.totalcount}`}
                    &nbsp;)
                  </span>
                )}
              </IonButton>
            </form>
          </div>
        ) : (
          <div>There were no alerts found.</div>
        )}
      </div>
    );
  }
}

export default withIonLifeCycle(PortfolioAlerts);
