import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const TrianglesTableRow = (props) => {
  let scans = props.triangle[props.scansymbol];

  return (
    <tr>
      <td className={"align-middle"}>
        <Link to={"/chart/" + props.scansymbol}>
          <b>{scans.shortsymbol}</b>
        </Link>
      </td>
      <td className="align-middle">
        <Link to={"/chart/" + props.scansymbol}>
          <div className="tiangle-img">
            <img
              width="32"
              src={scans.signal.triangle.img}
              alt={scans.shortsymbol}
            />
          </div>
        </Link>
      </td>
      <td className={"align-middle"}>
        Issued on {moment(scans.signal.triangle.date).format('MM-DD-YYYY')}
      </td>
    </tr>
  );
};

export default TrianglesTableRow;
