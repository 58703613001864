import React from "react";
import {IonCol, IonRow} from "@ionic/react";
import CSSTransition from "react-transition-group/CSSTransition";
import { Link } from "react-router-dom";
import mDown from "../../img/triangles/m-down@2x.png";
import mUp from "../../img/triangles/m-up@2x.png";
import wDown from "../../img/triangles/w-down@2x.png";
import wUp from "../../img/triangles/w-up@2x.png";
import dDown from "../../img/triangles/d-down@2x.png";
import dUp from "../../img/triangles/d-up@2x.png";

export default class PortfolioItemRowEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      netChange: false
    };
    this.handleEntered = this.handleEntered.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.last !== prevProps.last || this.props.netchange !== prevProps.netchange || this.props.pctchange !== prevProps.pctchange) {
      this.setState({
        netChange: true
      })
    }
  }
  handleEntered() {
    this.setState({
      netChange: false
    })
  }
  render() {
    return(
      <>
        <IonRow className={"ion-align-items-center"}>
          <IonCol size={4}>
            <Link to={"/chart/" + this.props.symbol}><small>{this.props.shortsymbol}</small></Link>
          </IonCol>
          <IonCol size={4} className={"ion-text-center"}>
          <span className={"price"}>
            <CSSTransition
              timeout={700}
              classNames="market-change"
              in={this.state.netChange}
              onEntered={this.handleEntered}
            >
              <Link to={"/chart/" + this.props.symbol}>
                <img
                  width="20"
                  src={this.props.triangles && this.props.triangles.dailytriangle === 1 ? dUp : dDown}
                  alt={this.props.symbol}
                />
                <img
                  width="20"
                  src={this.props.triangles && this.props.triangles.weektriangle === 1 ? wUp : wDown}
                  alt={this.props.symbol}
                  className="ml-1 mr-1"
                />
                <img
                  width="20"
                  src={this.props.triangles && this.props.triangles.monthtriangle === 1 ? mUp : mDown}
                  alt={this.props.symbol}
                />
              </Link>
            </CSSTransition>
          </span>
          </IonCol>
          <IonCol size={4} className={"ion-text-center"}>
            <span className={["change "+ this.props.changeclass]}>
              <CSSTransition
                timeout={700}
                classNames="market-change"
                in={this.state.netChange}
                onEntered={this.handleEntered}>
                <span>
                  {this.props.last}
                </span>
              </CSSTransition>
            </span>
          </IonCol>
        </IonRow>
      </>
    );
  }
}