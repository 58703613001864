import React from "react";
import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { search } from "ionicons/icons";
import { Link } from "react-router-dom";
import MarketPulse from "../components/MarketPulse/MarketPulse";
import TopStocks from "../components/TopStocks/TopStocks";

export default function TopLists(props) {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={"start"}>
            <IonMenuButton onClick={props.toggleMenu} />
          </IonButtons>
          <IonButtons slot="end">
            <Link to={"/search"}>
              <IonIcon
                icon={search}
                className={"search-button"}
                color={"light"}
              />
            </Link>
          </IonButtons>
          <IonTitle>MarketClub</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol className="mb-4">
              <MarketPulse id={props.id} ticket={props.ticket} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <TopStocks id={props.id} ticket={props.ticket} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
