import React from 'react';
import {IonList, IonSearchbar, IonSpinner} from '@ionic/react'
import {DATA_URL} from "../../utils/constants";
import SearchListItem from "./SearchListItem";

let dataSrc,
  symbol;

class Search extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      searching: false,
      error: null
    };
    this.getData = this.getData.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  getData() {
    // clear any errors
    this.setState({
      error: null
    });
    dataSrc = DATA_URL + '/symbolsearch/?s=' + symbol + "&id=" + this.props.id + "&ticket=" + this.props.ticket;
    // fetch the data
    fetch(dataSrc)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error.');
        }
      })
      .then(data => {
        this.setState({
          query: data.query,
          data: data.suggestions,
          searching: false
        });
      })
      .catch(error => {
        this.setState({
          error
        });
      });
  }
  onChangeHandler(e) {
    symbol = e.detail.value;
    this.setState({
      searching: true
    })
    this.getData();
  }
  displayResults() {
    if (this.state.searching) {
      return (
        <div className={"text-center"}>
          <IonSpinner color={"primary"}/>
        </div>
      )
    }
    else if (this.state.data !== undefined) {
      return (
        <IonList>
          {this.state.data.map(result => <SearchListItem {...result} key={result.data}/>)}
        </IonList>
      )
    }
  }

  render() {
    if (this.state.error) {
      return <p>{this.state.error.message}</p>;
    }
    return (
      <>
        <IonSearchbar
          placeholder={"Symbol Search"}
          debounce={800}
          animated={true}
          onIonChange={this.onChangeHandler}/>
        {this.displayResults()}
      </>
    )
  }
}

export default Search;