import React from 'react';
import {IonIcon, IonItem} from "@ionic/react";
import {addCircleOutline} from "ionicons/icons";

const SearchListItemAdd = props => (
  <IonItem
    button={true}
    routerLink={`/portfolio/add/${props.data}/${props.name}/${props.pfid}`}>
    <IonIcon
      icon={addCircleOutline}
      slot={"start"}/>

    {props.data} &nbsp;&nbsp;&nbsp;{props.value}
  </IonItem>
);

export default SearchListItemAdd;