import React from "react";
import CSSTransition from "react-transition-group/CSSTransition";

class QuoteRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueChange: false,
    };
    this.handleEntered = this.handleEntered.bind(this);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        valueChange: true,
      });
    }
  }
  handleEntered() {
    this.setState({
      valueChange: false,
    });
  }
  render() {
    return (
      <tr>
        <th scope="row">{this.props.item}</th>
        <td>
          <CSSTransition
            timeout={700}
            classNames="market-change"
            in={this.state.valueChange}
            onEntered={this.handleEntered}
          >
            <span>{this.props.value}</span>
          </CSSTransition>
        </td>
      </tr>
    );
  }
}
export default QuoteRow;
