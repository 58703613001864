import React from 'react';
import PortfolioItemRow from "./PortfolioItemRow";
import { DATA_URL } from '../../utils/constants';

const apiURL = DATA_URL +  '/quote/?format=json&s=';
let dataSrc,
  intervalID;

export default class PortfolioItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      quoteData: []
    };
  }
  componentDidMount() {
    this.getData();
    this.setTimer();
  }
  componentWillUnmount() {
    window.clearInterval(intervalID);
  }
  getData = () => {
    // clear any errors
    this.setState({
      error: null
    });
    // fetch the data
    if (this.props.symbol !== null) {
      dataSrc = apiURL + this.props.symbol + "&triangles=1&ticket=" + this.props.ticket + "&id=" + this.props.id;
    }
    fetch(dataSrc)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error.');
        }
      })
      .then(data => {
        this.setState({
          quoteData: data[`${this.props.symbol}`],
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false
        });
      });
  }
  setTimer() {
    intervalID = window.setInterval(this.getData, 30000);
  }
  render() {
    return(
      <PortfolioItemRow
          id={this.props.id}
          ticket={this.props.ticket}
          symbol={this.state.quoteData.symbol}
          shortsymbol={this.state.quoteData.shortsymbol}
          name={this.state.quoteData.name}
          last={this.state.quoteData.last}
          netchange={this.state.quoteData.netchange}
          pctchange={this.state.quoteData.pctchange}
          changeclass={this.state.quoteData.changeclass}
          triangles={this.state.quoteData.triangles}
          isPercent={this.props.isPercent}
          switchOfPricePercent={this.props.switchOfPricePercent}
        />
    )
  }
}
