import React from "react";
import { IonButton, IonCol, IonIcon, IonInput } from "@ionic/react";
import { pencilOutline, saveOutline } from "ionicons/icons";

export default class SubPortfolioItemRename extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pfNewName: this.props.name,
      canEdit: false
    };
  }

  editName = () => {
    this.setState({
        canEdit: !this.state.canEdit
    })
  }

  nameChange = (e) => {
    this.setState({ pfNewName: e.target.value });
  };

  saveName = () => {
    this.setState({
        canEdit: !this.state.canEdit
    })
    this.props.renameHandler(
        this.props.pfid,
        this.state.pfNewName
      )
  }

  render() {
    return (
      <tr>
        <td className="sub-portfolio-items">
          <IonCol size={9} className="sub-portfolio-items">
            <IonInput
              className={`ion-no-padding sub-portfolio-title ${this.state.canEdit ? "editName" : ""}`}
              type={"text"}
              onIonChange={this.nameChange}
              value={this.state.pfNewName}
              readonly={!this.state.canEdit}
            ></IonInput>
          </IonCol>
        </td>
        <td className="ion-text-end">
          <span className={"price"}>
            <IonCol size={3} className="ion-text-end p-0">
              {!this.state.canEdit ? (
                <IonButton
                  size={"small"}
                  onClick={this.editName}
                  disabled={this.props.isLoading}
                >
                  Edit <IonIcon icon={pencilOutline} slot={"end"} />
                </IonButton>
              ) : (
                <IonButton
                  size={"small"}
                  onClick={this.saveName}
                  id={this.props.pfid}
                  disabled={this.props.isLoading}
                >
                  Save <IonIcon icon={saveOutline} slot={"end"} />
                </IonButton>
              )}
            </IonCol>
          </span>
        </td>
      </tr>
    );
  }
}
