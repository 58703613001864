import React from "react";
import { IonItem } from "@ionic/react";

const SearchListItem = (props) => (
  <IonItem button={true} routerLink={"/chart/" + props.data}>
    {props.data.split("_")[0]}:{props.data.split("_")[1]} &nbsp;&nbsp;&nbsp;
    {props.value}
  </IonItem>
);

export default SearchListItem;
