import React from 'react';
import {IonSpinner, withIonLifeCycle} from "@ionic/react";
import {DATA_URL} from "../../utils/constants";
import Post from "./Post";

let dataSrc;

class BlogPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: null,
      data: []
    };
  }
  ionViewWillEnter() {
    this.getData();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.postid !== this.props.postid) {
      this.getData()
    }
  }
  getData() {
    // clear any errors
    this.setState({
      error: null
    });
    dataSrc = DATA_URL + '/blogposts/?content=1&membersblog=1&postid=' + this.props.postid + '&id=' + this.props.id + '&ticket=' + this.props.ticket;
    // fetch the data
    fetch(dataSrc)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Error.')
        }
      })
      .then(data => {
        this.setState({
          data: data,
          isLoading: false
        })
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false
        });
        // there is an error, try again after 60 sec.
        this.refreshAfterFailure();
      })
  }
  // there is an error, try again after 60 sec. (30 sec. timeout + 30 sec. timer)
  refreshAfterFailure() {
    // start the timer again
    window.setTimeout(this.setTimer, 30000);
  }

  render() {
    if (this.state.error) {
      return <p>{this.state.error.message}</p>;
    }
    if (this.state.isLoading) {
      return (
        <div>
          <IonSpinner color={"primary"} name={"dots"}/>
          <p>Loading...</p>
        </div>
      );
    }
    return (
      <>
        {this.state.data.map(post => <Post id={this.props.id} ticket={this.props.ticket} {...post} key={post.postid} />)}
      </>
    )
  }
}

export default withIonLifeCycle(BlogPost);