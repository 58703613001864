import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import {
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  withIonLifeCycle,
} from "@ionic/react";
import moment from "moment-timezone";
import mTz from "moment-timezone";
import indicators from "highcharts/indicators/indicators";
import ema from "highcharts/indicators/ema";

import dUp from "./../../img/triangles/triangle-up-d.svg";
import wUp from "./../../img/triangles/triangle-up-w.svg";
import mUp from "./../../img/triangles/triangle-up-m.svg";
import dDown from "./../../img/triangles/triangle-down-d.svg";
import wDown from "./../../img/triangles/triangle-down-w.svg";
import mDown from "./../../img/triangles/triangle-down-m.svg";

import { DATA_URL } from "../../utils/constants";

indicators(Highcharts);
ema(Highcharts);

// timezone stuff for highcharts
window.moment = moment;
mTz();

// data sources, variables
const dataMinute = DATA_URL + "/minute/",
  dataHour = DATA_URL + "/hour/",
  dataDay = DATA_URL + "/history/";

let startDate,
  dataSrc,
  dataInterval = "daily";

let triangleShapes = {
  triangleUpD: dUp,
  triangleDownD: dDown,
  triangleUpW: wUp,
  triangleDownW: wDown,
  triangleUpM: mUp,
  triangleDownM: mDown,
};

let chartData = [];

let trianglesUri = DATA_URL + `/triangledetail/?`;

class StockChart extends React.Component {
  // symbol is passed via props and URL with {match}
  // https://ionicframework.com/docs/react/navigation#url-parameters

  constructor(props) {
    super(props);
    this.state = {
      chartOptions: {},
      isLoading: true,
      error: null,
    };
    this.intervalChangeHandler = this.intervalChangeHandler.bind(this);
  }

  ionViewWillEnter() {
    this.getData();
    this.getTrianglesData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.symbol !== this.props.symbol) {
      // window.alert('StockChart -> componentDidUpdate');
      this.getData();
      this.getTrianglesData();
    }
  }

  // change start date of data depending on which interval is selected:
  // hour, minute, daily
  setStartDate() {
    let now = moment();
    let offsetDate;

    // console.log(now)

    switch (dataSrc) {
      case dataMinute:
        offsetDate = now.subtract(5, "days");
        break;
      case dataHour:
        offsetDate = now.subtract(10, "days");
        break;
      case dataDay:
        offsetDate = now.subtract(6, "months");
        break;
      default:
        offsetDate = now.subtract(6, "months");
        break;
    }

    // console.log(now)
    // console.log(offsetDate.format("YYYY-MM-DD"))

    return offsetDate.format("YYYY-MM-DD");
  }

  // chart data source change handler
  setDataSrc() {
    switch (dataInterval) {
      case "hour":
        dataSrc = dataHour;
        break;
      case "minute":
        dataSrc = dataMinute;
        break;
      case "daily":
        dataSrc = dataDay;
        break;
      default:
        break;
    }
    startDate = this.setStartDate();

    // build request params
    dataSrc = dataSrc + "?s=" + this.props.symbol;
    if (startDate !== null) {
      dataSrc += "&b=" + startDate;
    }
    dataSrc += "&f=json";
    dataSrc += `&id=${this.props.id}&ticket=${this.props.ticket}`;
  }

  // chart data source change handler
  intervalChangeHandler(e) {
    dataInterval = e.detail.value;
    this.getData();
    this.getTrianglesData();
  }

  getData() {
    // set the data zoom level, start date, and API source
    this.setDataSrc();

    fetch(dataSrc)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then(async (data) => {
        await this.getTrianglesData();
        chartData.d = data;
        this.onDataLoaded(chartData);
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  async getTrianglesData() {
    await fetch(
      trianglesUri +
        `s=${this.props.symbol}&id=${this.props.id}&ticket=${this.props.ticket}&mode=hc`
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then((triangles) => {
        triangles.chart.forEach((t) => {
          let dUtc = t.x
            .split("Date.UTC")[1]
            .substring(1)
            .slice(0, -1)
            .split(",");
          t.shape = `url(${triangleShapes[t.shape]})`;
          t.x = Date.UTC(+dUtc[0], +dUtc[1].trim() - 1, +dUtc[2].trim() + 1);
          return t;
        });

        // setting triangles data to chart
        chartData.t = triangles.chart
          ? triangles.chart.sort((a, b) => a.x - b.x)
          : [];
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  // data loaded callback, create and return the chart options
  onDataLoaded(data) {
    // create arrays for ohlc and volume data
    let ohlc = [],
      volume = [];

    data.d.forEach(function (point) {
      ohlc.push([
        point[0], // date
        point[1], // open
        point[2], // high
        point[3], // low
        point[4], // close
      ]);
      // volume not available for hour, minute, only daily
      if (point.length > 5) {
        volume.push([
          point[0], // date
          point[5], // volume
        ]);
      }
    });

    // add the ohlc and volume series data
    this.setState({
      isLoading: false,
      chartOptions: {
        time: {
          timezone: "America/New_York",
          timezoneOffset: moment.tz("America/New_York").utcOffset()
        },
        global: {
          useUTC: true,
        },
        colors: [
          "#4990e5",
          "#3c434c",
          "#86e573",
          "#f7a059",
          "#7d83e8",
          "#f5587f",
          "#f2e016",
          "#2b868f",
          "#db4f4f",
          "#46cfdb",
        ],
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
          verticalAlign: "bottom",
        },
        plotOptions: {
          series: {
            showInLegend: true,
          },
        },
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        stockTools: {
          gui: {
            enabled: false,
            buttons: [
              "typeChange",
              "indicators",
              "lines",
              "toggleAnnotations",
              "currentPriceIndicator",
              "zoomChange",
              "fullScreen",
            ],
          },
        },
        // 3 chart sections, price, volume, below chart
        yAxis: [
          {
            labels: {
              align: "left",
            },
            height: "80%",
          },
          {
            top: "80%",
            height: "20%",
          },
        ],
        series: [
          {
            data: ohlc,
            type: "ohlc",
            name: this.props.symbol,
            id: "the_chart",
            tooltip: {
              valueDecimals: 2,
            },
          },
          {
            data: volume,
            type: "column",
            yAxis: 1,
            name: "Volume",
            id: "volume",
          }, //,
          // {
          //   type: 'sma',
          //   linkedTo: 'the_chart',
          //   params: {
          //     period: 10
          //   }
          // }
          // the event marker flags (triangles)
          {
            type: "flags",
            onSeries: "the_chart",
            // shape: 'circlepin',
            width: 20,
            height: 17,
            data: data.t,
            y: 0,
          },
        ],
        rangeSelector: {
          enabled: true,
          buttons: [
            {
              type: "hour",
              count: 1,
              text: "1H",
            },
            {
              type: "day",
              count: 1,
              text: "1D",
            },
            {
              type: "day",
              count: 5,
              text: "5D",
            },
            {
              type: "month",
              count: 1,
              text: "1M",
            },
            {
              type: "month",
              count: 3,
              text: "3M",
            },
            {
              type: "month",
              count: 6,
              text: "6M",
            },
            // {
            //   type: 'year',
            //   count: 1,
            //   text: '1Y'
            // },
            // {
            //   type: 'year',
            //   count: 2,
            //   text: '2Y'
            // },
            // {
            //   type: 'year',
            //   count: 5,
            //   text: '5Y'
            // },
            // {
            //   type: "all",
            //   count: 1,
            //   text: "Max",
            // },
          ],
          selected: 4,
          inputEnabled: false,
          verticalAlign: "top",
          y: 10,
        },
      },
    });
  }

  render() {
    if (this.state.error) {
      return <p>{this.state.error.message}</p>;
    }
    if (this.state.isLoading) {
      return (
        <div>
          <IonSpinner color={"primary"} name={"dots"} />
          <p>Loading...</p>
        </div>
      );
    }
    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={this.state.chartOptions}
        />
        <IonItem>
          <IonLabel>Data Interval</IonLabel>
          <IonSelect
            interface={"popover"}
            onIonChange={this.intervalChangeHandler}
            placeholder={"Daily"}
            value={dataInterval}
          >
            <IonSelectOption value="minute">Minute</IonSelectOption>
            <IonSelectOption value="hour">Hour</IonSelectOption>
            <IonSelectOption value="daily">Daily</IonSelectOption>
          </IonSelect>
        </IonItem>
      </>
    );
  }
}

export default withIonLifeCycle(StockChart);
