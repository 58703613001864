import React from "react";
import TopStocksTableRow from "./TopStocksTableRow";

export default class TopStocksItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topListData: [],
    };
  }

  componentDidMount() {
    this.setState({
      topListData: this.props,
    });
  }

  render() {
    return (
      <TopStocksTableRow
        symbol={this.state.topListData.symbol}
        shortsymbol={this.state.topListData.shortsymbol}
        name={this.state.topListData.name}
        last={this.state.topListData.last}
        netchange={this.state.topListData.netchange}
        pctchange={this.state.topListData.pctchange}
        class={this.state.topListData.class}
        triangles={this.state.topListData.triangles}
        isPercent={this.props.isPercent}
        switchOfPricePercent={this.props.switchOfPricePercent}
      />
    );
  }
}
