import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import scanSlider from "../../img/smart-scan-slider.svg";

const AlertTableRow = (props) => {
  const subject = props.subject.split("Smart Scan Alert - ");
  const description = props.body.split("MarketClub Smart Scan Alert for ");

  return (
    <>
      <tr>
        <td>
          <strong className="title">
            {subject[0] === "" ? subject[1] : subject[0]}
          </strong>
          <br />
          <span className="meta">
            {props.timestamp ? moment(props.timestamp).format("lll") : ""}
          </span>
        </td>
        <td>
          <Link to={"/chart/" + props.symbol}><b>{props.shortsymbol}</b></Link>
        </td>
        <td>
          <div>
            <p>{description[0] === "" ? description[1] : description[0]}</p>
          </div>
          {props.score ? (
            <div id="scale">
              <div
                id="score"
                className={`score-${props.score} ${
                  props.score < 0 ? "down" : "up"
                }`}
              >
                <span className="operator">{props.score < 0 ? "" : "+"}</span>
                {props.score}
              </div>
              <img
                src={scanSlider}
                width="16"
                height="16"
                alt={`Smart Scan Analysis for ${props.symbol}`}
              />
              <div id="labels">
                <span>Strong Downtrend</span>
                <span className="ctr">Sideways</span>
                <span className="last">Strong Uptrend</span>
              </div>
            </div>
          ) : null}
        </td>
      </tr>
    </>
  );
};

export default AlertTableRow;
