import { NavContext } from "@ionic/react";
import { useState, useEffect, useContext } from "react";
import { DATA_URL } from "../../utils/constants";

const useForm = (callback, validate) => {
  const { navigate } = useContext(NavContext);
  const [values, setValues] = useState({});
  const [responseValue, setResponseValue] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(true);

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      setHasError(true);
    } else if (Object.keys(errors).length === 0 && isSubmitting) {
      getReminderData();
      setHasError(false);
      callback();
    }
  }, [errors, hasError]);

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
      setErrors(validate(values));
      setIsSubmitting(true);
    }
  };

  const toLoginPage = (event) => {
    if (event) {
      event.preventDefault();
      navigate("/login", "back");
    }
  };

  const getReminderData = async () => {
    let api =
      DATA_URL + `/clubportfolio/ticket/?email=${values.email}&forgotpass=${1}`;
    // get forgot password request
    try {
      const response = await fetch(api);
      if (response.ok) {
        const data = await response.json();
        if (data.status === 1) {
          setResponseValue((responseValue) => ({
            ...responseValue,
            successMessage: data.message,
            errorMessage: null,
          }));
          setIsSubmitting(false);
          setHasError(true);
        } else {
          setResponseValue((responseValue) => ({
            ...responseValue,
            errorMessage: data.error,
            successMessage: null,
          }));
          setIsSubmitting(false);
          setHasError(true);
        }
      }
    } catch (e) {
      setResponseValue((responseValue) => ({
        ...responseValue,
        message: e,
      }));
      setIsSubmitting(false);
      setHasError(true);
    }
  };

  const handleChange = (event) => {
    event.persist();
    setResponseValue((responseValue) => ({
      ...responseValue,
      errorMessage: null,
      successMessage: null,
    }));
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    responseValue,
    isSubmitting,
    hasError,
    toLoginPage,
  };
};

export default useForm;
