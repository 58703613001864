import React from "react";
import { Link } from "react-router-dom";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
} from "@ionic/react";

const cardStyle = {
  margin: "0 0 20px 0",
};
const excerptStyle = {
  color: "#000000",
  marginBottom: "12px",
};
const metaStyle = {
  color: "#86888f",
  fontSize: "12px",
  textTransform: "uppercase",
};
const titleStyle = {
  fontSize: "18px",
};

const BlogLink = (props) => (
  <IonCard style={cardStyle}>
    <IonCardHeader>
      <IonCardTitle style={titleStyle}>
        <Link to={"/blog/" + props.postid}>{props.title}</Link>
      </IonCardTitle>
      <IonCardSubtitle style={metaStyle}>
        <span className="date">{props.dateago}</span>
      </IonCardSubtitle>
    </IonCardHeader>
    <IonCardContent>
      <p style={excerptStyle}>{props.excerpt}</p>
      <Link to={"/blog/" + props.postid}>
        <IonButton>Read More</IonButton>
      </Link>
    </IonCardContent>
  </IonCard>
);

export default BlogLink;
