import React from "react";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import Search from "../components/Search/Search";

export default function SearchPage(props) {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/pulse"/>
          </IonButtons>
          <IonTitle>
            Search
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <Search id={props.id} ticket={props.ticket} />

      </IonContent>
    </IonPage>
  )
}
