import React from "react";
import { IonSpinner, withIonLifeCycle } from "@ionic/react";
import { DATA_URL } from "../../utils/constants";
import { Link } from "react-router-dom";
// import cart32 from "../../img/icons/chart-32-off.png";
// import target32 from "../../img/icons/target-32-off.png";
// import download32 from "../../img/icons/download-32-off.png";

const featuredAnalysisURL = DATA_URL + "/clubportfolio/featured/?";
let intervalID;

class FeaturedAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      featuredAnalysis: {},
      triangleinfo: {},
      tick: {},
      triangles: {},
      isLoading: true,
      error: null,
    };
    this.getFeaturedAnalysis = this.getFeaturedAnalysis.bind(this);
    this.setTimer = this.setTimer.bind(this);
  }

  // fetch data after component loaded, set up timer for data refresh
  ionViewWillEnter() {
    this.getFeaturedAnalysis();
    this.setTimer();
  }
  ionViewWillLeave() {
    window.clearInterval(intervalID);
  }

  getFeaturedAnalysis() {
    // clear any errors
    this.setState({
      error: null,
    });
    // fetch portfolio alerts
    fetch(
      featuredAnalysisURL + `id=${this.props.id}&ticket=${this.props.ticket}`
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then((analysis) => {
        this.setState({
          featuredAnalysis: analysis,
          triangleinfo: analysis.triangleinfo,
          tick: analysis.tick,
          triangles: analysis.triangles,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
        // there is an error, try again after a defined interval
        window.clearInterval(intervalID);
        this.refreshAfterFailure();
      });
  }

  // refresh the data periodically (30 sec.)
  setTimer() {
    intervalID = window.setInterval(this.getData, 30000);
  }
  // there is an error, try again after 60 sec. (30 sec. timeout + 30 sec. timer)
  refreshAfterFailure() {
    // start the timer again
    window.setTimeout(this.setTimer, 30000);
  }

  returnPortfolioButton = () => {
    if (
      this.props.portfolio &&
      Object.keys(this.props.portfolio).length !== 0
    ) {
      if (
        this.props.portfolio.symbols.findIndex(
          (element) => element.symbol === this.state.featuredAnalysis.symbol
        ) !== -1
      ) {
        return (
          <div className="float-right mr-1">
            <small>
              <span>{this.state.featuredAnalysis.shortsymbol} is in </span>
              <Link to={"/portfolio"}>My Portfolio</Link>
            </small>
          </div>
        );
      }
    }
    return (
      <div className="float-right mr-1">
        <small>
          <Link to={"/portfolio/add/" + this.state.featuredAnalysis.symbol}>
            Add {this.state.featuredAnalysis.shortsymbol} to My Portfolio
          </Link>
        </small>
      </div>
    );
  };

  // render function will be called again when state is set in getData()
  render() {
    if (this.state.error) {
      return <p>{this.state.error.message}</p>;
    }
    if (this.state.isLoading) {
      return (
        <div>
          <IonSpinner color={"primary"} name={"dots"} />
          <p>Loading...</p>
        </div>
      );
    }
    return (
      <div className={"featured-analysis"} id="analysis-main">
        <table border="0" cellPadding="0" cellSpacing="0" className="col-12">
          <tbody>
            <tr>
              <td className="ion-text-center">
                <Link to={"/chart/" + this.state.featuredAnalysis.symbol}>
                  <img
                    src={this.state.featuredAnalysis.chartimg}
                    alt={this.state.featuredAnalysis.symbol}
                  />
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                <Link to={"/chart/" + this.state.featuredAnalysis.symbol}>
                  <h3 className={"mt-3 link"}>
                    {this.state.featuredAnalysis.shortsymbol}
                  </h3>
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  border="0"
                  cellPadding="0"
                  cellSpacing="0"
                  className="analysis-table"
                >
                  <thead>
                    <tr className="ion-text-center label-block">
                      <th>Open</th>
                      <th>High</th>
                      <th>Low</th>
                      <th>{this.state.featuredAnalysis.shortsymbol} Price</th>
                      <th>Change</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="ion-text-center content-block">
                      <th>{this.state.tick.open}</th>
                      <th>{this.state.tick.high}</th>
                      <th>{this.state.tick.low}</th>
                      <th>{this.state.tick.last}</th>
                      <th>
                        <span
                          className={
                            this.state.tick.netchange < 0 ? "down" : "up"
                          }
                        >
                          {this.state.tick.netchange}
                        </span>
                      </th>
                    </tr>
                  </tbody>
                  {/*<tfoot>*/}
                  {/*  <tr>*/}
                  {/*    <td colSpan={5}>*/}
                  {/*      <div className="float-left ml-1">*/}
                  {/*        <Link*/}
                  {/*          to={"/chart/" + this.state.featuredAnalysis.symbol}*/}
                  {/*        >*/}
                  {/*          <img*/}
                  {/*            src={cart32}*/}
                  {/*            alt={this.state.featuredAnalysis.symbol}*/}
                  {/*            width="16"*/}
                  {/*            height="16"*/}
                  {/*          />*/}
                  {/*        </Link>*/}
                  {/*        <Link*/}
                  {/*          to={"/chart/" + this.state.featuredAnalysis.symbol}*/}
                  {/*          className="ml-1 mr-1"*/}
                  {/*        >*/}
                  {/*          <img*/}
                  {/*            src={target32}*/}
                  {/*            alt={this.state.featuredAnalysis.symbol}*/}
                  {/*            width="16"*/}
                  {/*            height="16"*/}
                  {/*          />*/}
                  {/*        </Link>*/}
                  {/*        <Link*/}
                  {/*          to={"/chart/" + this.state.featuredAnalysis.symbol}*/}
                  {/*        >*/}
                  {/*          <img*/}
                  {/*            src={download32}*/}
                  {/*            alt={this.state.featuredAnalysis.symbol}*/}
                  {/*            width="16"*/}
                  {/*            height="16"*/}
                  {/*          />*/}
                  {/*        </Link>*/}
                  {/*      </div>*/}
                  {/*      {this.returnPortfolioButton()}*/}
                  {/*    </td>*/}
                  {/*  </tr>*/}
                  {/*</tfoot>*/}
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default withIonLifeCycle(FeaturedAnalysis);
