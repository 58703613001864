import React from "react";
import { withIonLifeCycle, IonSlides, IonSpinner } from "@ionic/react";
import Market from "./Market";
import Indicator from "./Indicator";
import { DATA_URL } from "../../utils/constants";

// INO market data source
// for testing:
// let dataSrc = DATA_URL + "/fail";
let dataSrc = DATA_URL + "/indicators/";
let intervalID;

const slideOptions = {
  slidesPerView: 3,
  spaceBetween: 20,

  breakpoints: {
    375: {
      slidesPerView: 4,
      spaceBetween: 10,
    },
    600: {
      slidesPerView: 5,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 7,
      spaceBetween: 20,
    },
  },
};

// market pulse bar component
class MarketPulse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markets: [],
      isLoading: true,
      error: null,
      indicatorClass: "",
      indicator: "",
    };
    this.getData = this.getData.bind(this);
    this.setTimer = this.setTimer.bind(this);
  }

  // fetch data after component loaded, set up timer for data refresh
  ionViewWillEnter() {
    // console.log('MarketPulse > ionVIewWillEnter');
    // console.log('MarketPulse > ionVIewWillEnter > intervalID' + intervalID);
    this.getData();
    this.setTimer();
    // console.log('MarketPulse > ionVIewWillEnter > intervalID' + intervalID);
  }
  ionViewWillLeave() {
    // console.log('MarketPulse > ionViewWillLeave');
    window.clearInterval(intervalID);
    // console.log('MarketPulse > intervalID' + intervalID);
  }

  getData() {
    // clear any errors
    this.setState({
      error: null,
    });
    // fetch the data
    fetch(dataSrc + "?id=" + this.props.id + "&ticket" + this.props.ticket)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then((data) => {
        this.setState({
          markets: data.markets,
          isLoading: false,
          indicatorClass: data.indicatorClass,
          indicator: data.indicator,
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
        // there is an error, try again after 60 sec.
        window.clearInterval(intervalID);
        this.refreshAfterFailure();
      });
  }

  // refresh data periodically
  setTimer() {
    intervalID = window.setInterval(this.getData, 30000);
  }
  // there is an error, try again after 60 sec. (30 sec. timeout + 30 sec. timer)
  refreshAfterFailure() {
    // for testing:
    // dataSrc = DATA_URL + "/indicators/";
    // start the timer again
    window.setTimeout(this.setTimer, 30000);
  }

  // render function will be called again when state is set in getData()
  render() {
    if (this.state.error) {
      return <p>{this.state.error.message}</p>;
    }
    if (this.state.isLoading) {
      return (
        <div id={"market-pulse"}>
          <IonSpinner color={"primary"} name={"dots"} />
          <p>Loading...</p>
        </div>
      );
    }
    return (
      <div className={this.state.indicatorClass} id={"market-pulse"}>
        <IonSlides options={slideOptions}>
          {this.state.markets.map((market) => (
            <Market {...market} key={market.id} id={market.id} />
          ))}
        </IonSlides>
        <Indicator
          className={this.state.indicatorClass}
          indicator={this.state.indicator}
        />
      </div>
    );
  }
}

export default withIonLifeCycle(MarketPulse);
