import React from "react";
import { IonSpinner, withIonLifeCycle, IonButton } from "@ionic/react";
import TrianglesTableRow from "./TrianglesTableRow";
import { DATA_URL } from "../../utils/constants";

const trianglesURL = DATA_URL + "/clubportfolio/scans/?";
let intervalID;
let limit = 10;

class PortfolioTriangles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scansymbols: [],
      scandata: {},
      scancount: 0,
      isLoading: true,
      error: null,
      totalcount: 0,
      isLoadData: false,
    };
    this.getData = this.getData.bind(this);
    this.setTimer = this.setTimer.bind(this);
  }
  // fetch data after component loaded, set up timer for data refresh
  ionViewWillEnter() {
    limit = 10;
    this.getData();
    this.setTimer();
  }
  ionViewWillLeave() {
    window.clearInterval(intervalID);
  }

  getData() {
    // clear any errors
    this.setState({
      error: null,
      totalcount: 0,
      isLoadData: true,
    });
    // fetch the data
    fetch(
      trianglesURL +
        "id=" +
        this.props.id +
        "&ticket=" +
        this.props.ticket +
        "&scantype=triangles&limit=" +
        limit
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then((data) => {
        this.setState({
          scansymbols: data.scansymbols,
          scandata: data.scandata,
          scancount: data.scancount,
          isLoading: false,
          totalcount: data.totalcount,
          isLoadData: false,
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
          totalcount: 0,
          isLoadData: false,
        });
        // there is an error, try again after a defined interval
        window.clearInterval(intervalID);
        this.refreshAfterFailure();
      });
  }

  showMoreTriangles = (e) => {
    e.preventDefault();
    limit += 10;
    this.getData();
  };

  // refresh the data periodically (30 sec.)
  setTimer() {
    intervalID = window.setInterval(this.getData, 30000);
  }
  // there is an error, try again after 60 sec. (30 sec. timeout + 30 sec. timer)
  refreshAfterFailure() {
    // start the timer again
    window.setTimeout(this.setTimer, 30000);
  }

  // render function will be called again when state is set in getData()
  render() {
    if (this.state.error) {
      return <p>{this.state.error.message}</p>;
    }
    if (this.state.isLoading) {
      return (
        <div>
          <IonSpinner color={"primary"} name={"dots"} />
          <p>Loading...</p>
        </div>
      );
    }
    return (
      <div className={"portfolio-table"}>
        {this.state.scansymbols && this.state.scansymbols.length && (
          <table
            border="0"
            cellPadding="0"
            cellSpacing="0"
            className="table table-condensed"
          >
            <tbody>
              {this.state.scansymbols.map((scansymbol, key) => {
                return (
                  <TrianglesTableRow
                    scansymbol={scansymbol}
                    key={key}
                    triangle={this.state.scandata}
                  />
                );
              })}
            </tbody>
          </table>
        )}
        {this.state.scansymbols && this.state.scansymbols.length ? (
          <form
            onSubmit={this.showMoreTriangles}
            className="ion-text-center mt-2"
          >
            <IonButton
              type={"submit"}
              size={"small"}
              expand={"block"}
              disabled={this.state.totalcount <= limit}
            >
              {this.state.isLoadData ? (
                <IonSpinner name={"circles"} />
              ) : (
                <span>
                  Show more (&nbsp;
                  {this.state.totalcount <= limit
                    ? `${this.state.totalcount} of ${this.state.totalcount}`
                    : `${limit} of ${this.state.totalcount}`}
                  &nbsp;)
                </span>
              )}
            </IonButton>
          </form>
        ) : (
          <div className="no-triangles">There were no triangles found.</div>
        )}
      </div>
    );
  }
}

export default withIonLifeCycle(PortfolioTriangles);
