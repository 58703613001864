import React from "react";
import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonRouterLink,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  search,
  call,
  mail,
  logoFacebook,
  logoTwitter,
  logoYoutube,
} from "ionicons/icons";
import { Link } from "react-router-dom";

export default function SupportPage(props) {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={"start"}>
            <IonMenuButton onClick={props.toggleMenu} />
          </IonButtons>
          <IonButtons slot="end">
            <Link to={"/search"}>
              <IonIcon
                icon={search}
                className={"search-button"}
                color={"light"}
              />
            </Link>
          </IonButtons>
          <IonTitle>MarketClub</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <h1>MarketClub Support Team</h1>
              <hr />
              <p>
                Our support team is ready to help, with live support available
                Monday through Friday between 9 a.m. and 5 p.m (ET).
              </p>

              <h2>Call Us</h2>
              <IonGrid>
                <IonRow
                  className={
                    "ion-justify-content-center ion-align-items-center"
                  }
                >
                  <IonCol size={"1"} className={"pl-0"}>
                    <IonIcon
                      icon={call}
                      size={"large"}
                      className={"phone-icon"}
                    />
                  </IonCol>
                  <IonCol size={"11"}>
                    <span className={"phone-num"}>1-800-538-7424</span>
                  </IonCol>
                </IonRow>
              </IonGrid>

              <h2>Email Us</h2>
              <IonGrid>
                <IonRow
                  className={
                    "ion-justify-content-center ion-align-items-center"
                  }
                >
                  <IonCol size={"1"} className={"pl-0"}>
                    <IonRouterLink
                      href={"https://club.ino.com/join/contact.html"}
                    >
                      <IonIcon
                        icon={mail}
                        size={"large"}
                        className={"email-icon"}
                      />
                    </IonRouterLink>
                  </IonCol>
                  <IonCol size={"11"}>
                    <b className={"email-link"}>
                      <IonRouterLink
                        href={"https://club.ino.com/join/contact.html#send-message"}
                      >
                        Send A Message
                      </IonRouterLink>
                    </b>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <h2>Follow Us</h2>
              <IonRouterLink href={"https://twitter.com/MarketClub"}>
                <IonIcon
                  icon={logoTwitter}
                  size={"large"}
                  className={"TW-icon"}
                />
              </IonRouterLink>
              <IonRouterLink href={"https://www.facebook.com/TradeTriangles/"}>
                <IonIcon
                  icon={logoFacebook}
                  size={"large"}
                  className={"FB-icon"}
                />
              </IonRouterLink>
              <IonRouterLink href={"https://www.youtube.com/user/marketclub"}>
                <IonIcon
                  icon={logoYoutube}
                  size={"large"}
                  className={"YT-icon"}
                />
              </IonRouterLink>
              <h2>MarketClub</h2>
              <p>
                INO.com, Inc.
                <br />
                228 Park Avenue South, Suite 320
                <br />
                New York, NY
                10003-1502
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
