import React from "react";
import CSSTransition from "react-transition-group/CSSTransition";
import { Link } from "react-router-dom";
import dUp from "./../../img/triangles/triangle-up-d.svg";
import wUp from "./../../img/triangles/triangle-up-w.svg";
import mUp from "./../../img/triangles/triangle-up-m.svg";
import dDown from "./../../img/triangles/triangle-down-d.svg";
import wDown from "./../../img/triangles/triangle-down-w.svg";
import mDown from "./../../img/triangles/triangle-down-m.svg";

export default class TopStocksTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priceChange: false,
      netChange: false,
      pctChange: false,
      change: "",
      changeLabel: "",
    };
    this.handleEntered = this.handleEntered.bind(this);
    this.toggleChangeKey = this.toggleChangeKey.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.last !== prevProps.last) {
      this.setState({
        priceChange: true,
      });
    }
    if (this.props.netchange !== prevProps.netchange) {
      this.setState({
        netChange: true,
      });
    }
    if (this.props.pctchange !== prevProps.pctchange) {
      this.setState({
        pctChange: true,
      });
    }
  }

  handleEntered() {
    this.setState({
      priceChange: false,
      netChange: false,
      pctChange: false
    });
  }

  toggleChangeKey() {
    this.props.switchOfPricePercent();
  }

  render() {
    return (
      <tr>
        <td>
          <Link to={"/chart/" + this.props.symbol}>
            <b>{this.props.shortsymbol}</b>
          </Link>
        </td>
        <td className="">
          <span>
            <CSSTransition
              timeout={700}
              classNames="market-change"
              in={this.state.priceChange}
              onEntered={this.handleEntered}
            >
              <Link to={"/chart/" + this.props.symbol}>
                <img
                  width="20"
                  src={
                    this.props.triangles &&
                    this.props.triangles.dailytriangle === 1
                      ? dUp
                      : dDown
                  }
                  alt={this.props.symbol}
                />
                <img
                  width="20"
                  src={
                    this.props.triangles &&
                    this.props.triangles.weektriangle === 1
                      ? wUp
                      : wDown
                  }
                  alt={this.props.symbol}
                  className="ml-1 mr-1"
                />
                <img
                  width="20"
                  src={
                    this.props.triangles &&
                    this.props.triangles.monthtriangle === 1
                      ? mUp
                      : mDown
                  }
                  alt={this.props.symbol}
                />
              </Link>
            </CSSTransition>
          </span>
        </td>
        <td className={["text-center", this.props.class].join(" ")}>
          <span className={["change " + this.props.class]}>
            <CSSTransition
              timeout={700}
              classNames="market-change"
              in={this.state.netChange}
              onEntered={this.handleEntered}
            >
              <small>{this.props.last}</small>
            </CSSTransition>
          </span>
        </td>
        <td className={["rt", this.props.class].join(" ")}>
          <span className={["portfolio-table pctchange " + this.props.class]}>
            <CSSTransition
              timeout={200}
              classNames="market-change"
              in={this.state.pctChange}
              onEntered={this.handleEntered}
            >
              <span
                className={["badge", "badge-" + this.props.class].join(" ")}
                onClick={this.toggleChangeKey}
              >
                <small>
                  {this.props && this.props.isPercent
                    ? this.props.pctchange
                      ? `${this.props.pctchange}%`
                      : ""
                    : this.props.netchange
                    ? `$${this.props.netchange.slice(1)}`
                    : ""}
                </small>
              </span>
            </CSSTransition>
          </span>
        </td>
      </tr>
    );
  }
}
