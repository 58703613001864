import React from "react";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
} from "@ionic/react";
import SearchAdd from "../components/Search/SearchAdd";
import MarketPulse from "../components/MarketPulse/MarketPulse";

class PortfolioSearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/subportfolio" />
            </IonButtons>
            <IonTitle>Add Symbol</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCol>
            <MarketPulse id={this.props.id} ticket={this.props.ticket} />
          </IonCol>
          <SearchAdd
            name={this.props.match.params.name}
            pfid={this.props.match.params.pfid}
            id={this.props.id}
            ticket={this.props.ticket}
          />
        </IonContent>
      </IonPage>
    );
  }
}

export default PortfolioSearchPage;
