import React from "react";
import { Link } from "react-router-dom";
import { chevronForwardOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

export default class SubPortfolioItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
    };
  }
  render() {
    return (
      <tr>
        <td className="sub-portfolio-items">
          <Link
            to={"/portfolio/" + this.props.name + "/" + this.props.pfid}
            name={this.props.name}
            className="sub-portfolio-title"
          >
            <span>{this.props.name}</span>
          </Link>
        </td>
        <td className={"ion-text-center"}>
          <span className={"price"}>
            <Link
              to={"/portfolio/" + this.props.name + "/" + this.props.pfid}
              name={this.props.name}
            >
              <IonIcon
                className={"align_middle"}
                icon={chevronForwardOutline}
                slot={"start"}
                color={"primary"}
                size={"small"}
              />
            </Link>
          </span>
        </td>
      </tr>
    );
  }
}
