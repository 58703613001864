import React from "react";
import {
  IonContent,
  IonPage,
  IonText,
  IonButton,
  IonCol,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import MC_logo from "../../img/MC_logo.svg";
import useForm from "./useForm";
import { Redirect } from "react-router-dom";

const ForgotPassword = (props) => {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    responseValue,
    isSubmitting,
    hasError,
    toLoginPage,
  } = useForm(login, validate);
  function validate(values) {
    let errors = {};
    if (!values.email) {
      errors.email = "Please enter your email address";
    } else if (!/\S+@\S+/.test(values.email)) {
      errors.email = "Please include an @ symbol in your email address";
    } else if (!/\S+\./.test(values.email)) {
      errors.email = "Incomplete address. Please Try again";
    }

    return errors;
  }
  const [loggedIn, setLoggedIn] = React.useState(false);

  function callbackFunction(childData) {
    setLoggedIn(childData);
  }
  function login() {
    setLoggedIn(loggedIn);
    callbackFunction(true);
    return <Redirect to="/default" />;
  }
  return (
    <IonPage>
      <IonContent>
        <IonRow>
          <IonCol>
            <div className={"text-center mt-5 mb-3"}>
              <img className="logoImg" src={MC_logo} alt="logo" />
            </div>
            {responseValue.successMessage && responseValue.successMessage && (
              <>
                <IonRow className="ion-align-items-center ion-text-center">
                  <IonCol className="px-5">
                    <IonText>
                      <p className={"alert alert-success successMessage"}>
                        <b>Success</b>
                        <br /> {responseValue.successMessage}
                      </p>
                    </IonText>
                    <IonText>
                      <p>Please check your email for a password reminder.</p>
                    </IonText>
                  </IonCol>
                </IonRow>
                <form onSubmit={toLoginPage} noValidate={"noValidate"}>
                  <IonRow className={"ion-align-items-center"}>
                    <IonCol className={"px-5 "}>
                      <div>
                        <IonButton
                          type={"submit"}
                          size={"medium"}
                          expand={"block"}
                        >
                          Log In
                        </IonButton>
                      </div>
                    </IonCol>
                  </IonRow>
                </form>
              </>
            )}
            {responseValue.errorMessage && responseValue.errorMessage && (
              <>
                <div className="ion-align-items-center ion-text-center">
                  <div className="px-5 py-3">
                    <IonText>
                      <p className={"alert alert-danger errorMessage"}>
                        <b>Error</b> <br /> {responseValue.errorMessage}
                      </p>
                    </IonText>
                    <p>
                      Please enter your correct email address, or contact us for
                      support.
                    </p>
                    <div>
                      <ion-router-link href="https://www.ino.com/help/email.html">
                        <b>Contact Us</b>
                      </ion-router-link>
                    </div>
                  </div>
                </div>
              </>
            )}
          </IonCol>
        </IonRow>
        {!responseValue.successMessage ? (
          <>
            <IonRow>
              <IonCol className={"text-center px-5"}>
                <IonText>
                  <b>Need a Password Reminder?</b>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className={"text-center px-5 pb-4"}>
                <IonText>
                  Enter your email address and we'll send you a password
                  reminder right away.
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className={"mb-2"}>
              <IonCol className={"px-5 "}>
                <input
                  type="email"
                  name="email"
                  className={`email form-control no-border ${
                    errors.email && "is-dangerLogin"
                  }`}
                  placeholder="Email Address"
                  value={values.email || ""}
                  onChange={handleChange}
                />
                {errors.email && (
                  <IonText color="danger">
                    <small>{errors.email}</small>
                  </IonText>
                )}
              </IonCol>
            </IonRow>
            <form onSubmit={toLoginPage} noValidate={"noValidate"}>
              <IonRow className={"ion-align-items-center"}>
                <IonCol className={"text-right back-to-login px-5 py-0"}>
                  <button type={"submit"}>Log In</button>
                </IonCol>
              </IonRow>
            </form>
            <form onSubmit={handleSubmit} noValidate={"noValidate"}>
              <IonRow className={"ion-align-items-center"}>
                <IonCol className={"px-5 "}>
                  <div>
                    <IonButton
                      type={"submit"}
                      color={"success"}
                      size={"medium"}
                      expand={"block"}
                      disabled={isSubmitting && !Object.keys(errors).length}
                    >
                      Send Password Reminder
                    </IonButton>
                  </div>
                </IonCol>
              </IonRow>
            </form>
            <IonRow hidden={hasError}>
              <IonCol className="ion-text-center">
                <IonSpinner color="primary" />
              </IonCol>
            </IonRow>
          </>
        ) : null}
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
