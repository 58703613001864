import React from "react";
import QuoteRow from "./QuoteRow";

const QuoteTable = (props) => (
  <table border="0" cellPadding="0" cellSpacing="0" className="quote-table">
    <tbody>
      {props.fundamental.map((item) => (
        <QuoteRow {...item} key={item.tag} />
      ))}
    </tbody>
  </table>
);

export default QuoteTable;
