import React from "react";
import { IonButton, IonIcon, IonSpinner, withIonLifeCycle } from "@ionic/react";
import { addOutline, chevronBackOutline } from "ionicons/icons";
import { DATA_URL } from "../../utils/constants";

const addSymbolURL = DATA_URL + "/clubportfolio/add/?";
class PortfolioAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      addData: {},
    };
  }

  // add symbol
  addSymbol = (symbol, pfid) => {
    // clear any errors
    this.setState({
      error: null,
    });
    let dataSrc = addSymbolURL + `s=${symbol}&id=${this.props.id}&ticket=${this.props.ticket}&pfid=${pfid}`;
    // fetch the data
    fetch(dataSrc)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then((data) => {
        this.setAddData(data, symbol);
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };

  // set portfolio add data, returned from JSON to state
  setAddData = (data) => {
    this.setState({
      addData: data,
      isLoading: false,
    });
  };
  ionViewWillEnter() {
    // check for ID and ticket, fetch API URL
    if (this.props.isLoggedIn()) {
      this.addSymbol(this.props.symbol, this.props.pfid);
    }
  }
  renderForm() {
    if (this.props.isLoggedIn()) {
      if (this.state.isLoading) {
        return (
          <div>
            <IonSpinner color={"primary"} name={"dots"} />
            <p>Loading...</p>
          </div>
        );
      } else {
        // logged in, check status
        if (this.state.addData.status === 1) {
          // success
          return (
            <>
              <p className={"alert alert-info"}>
                Symbol {this.props.symbol} has been added to your portfolio.
              </p>
              <p>Return to your portfolio or add another symbol.</p>
              <div>
                <IonButton routerLink={"/portfolio/" + this.props.name + '/' + this.props.pfid} size={"small"}>
                  <IonIcon icon={chevronBackOutline} slot={"start"} /> Portfolio
                </IonButton>
                <IonButton routerLink={"/portfolio/search/" + this.props.name + '/' + this.props.pfid} size={"small"}>
                  Add <IonIcon icon={addOutline} slot={"end"} />
                </IonButton>
              </div>
            </>
          );
        } else if (this.state.addData.status === 0) {
          // error / warning
          return (
            <>
              <p
                className={
                  this.state.addData.warning
                    ? "alert alert-warning"
                    : "alert alert-danger"
                }
              >
                {this.state.addData.warning
                  ? "Alert: " + this.state.addData.warning
                  : "Error: " + this.state.addData.error}
              </p>
              <p>
                Please return to your portfolio and try again, or contact us for
                support.
                <ion-router-link href="https://www.ino.com/help/email.html">
                  {" "}
                  Contact Us
                </ion-router-link>
              </p>
              <IonButton routerLink={"/portfolio/" + this.props.name + '/' + this.props.pfid} size={"small"}>
                <IonIcon icon={chevronBackOutline} slot={"start"} /> Portfolio
              </IonButton>
            </>
          );
        } else if (Object.keys(this.state.addData).length === 0) {
          // page accessed independently, no data
          return (
            <div>
              <p>Please return to your portfolio to add a symbol.</p>
              <IonButton routerLink={"/portfolio/" + this.props.name + '/' + this.props.pfid} size={"small"}>
                <IonIcon icon={chevronBackOutline} slot={"start"} /> Portfolio
              </IonButton>
            </div>
          );
        }
      }
    } else {
      // logged out
      return (
        <>
          <p>Please log in to add a symbol.</p>
          <IonButton routerLink={"/portfolio/" + this.props.name + '/' + this.props.pfid} size={"small"}>
            <IonIcon icon={chevronBackOutline} slot={"start"} /> Portfolio
          </IonButton>
        </>
      );
    }
  }
  render() {
    return <>{this.renderForm()}</>;
  }
}

export default withIonLifeCycle(PortfolioAdd);
