import React from "react";
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonReorder,
  IonRow,
} from "@ionic/react";
import { removeCircle, reorderThreeOutline } from "ionicons/icons";
import { Link } from "react-router-dom";

export default class SubPortfolioItemEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
    };
    this.ionSlider = React.createRef();
  }

  // open the slider to confirm delete
  deleteXButton = (e) => {
    e.preventDefault();
    this.ionSlider.current.open();
  };
  render() {
    return (
      <IonItemSliding ref={this.ionSlider}>
        <IonItem className={"portfolio-item portfolio-table"}>
          <Link
            to={"/portfolio/delete/" + this.props.pfid}
            onClick={this.deleteXButton}
            name={this.props.name}
          >
            <IonIcon
              className={"align_middle"}
              icon={removeCircle}
              slot={"start"}
              color={"danger"}
              size={"large"}
            />
          </Link>
          <IonReorder slot={"end"}>
            <IonIcon icon={reorderThreeOutline} />
          </IonReorder>
          <IonGrid>
            <IonRow className={"ion-align-items-center"}>
              <IonCol>{this.props.name}</IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
        <IonItemOptions>
          <IonItemOption
            color="danger"
            onClick={this.props.clickHandler}
            id={this.props.pfid}
          >
            Delete
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    );
  }
}
