import React from "react";
import {
  IonButton,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonRow,
  IonSpinner,
  withIonLifeCycle,
} from "@ionic/react";
import { addOutline, chevronBackOutline } from "ionicons/icons";
import { DATA_URL } from "../../utils/constants";

const addPortfolioURL = DATA_URL + "/clubportfolio/group/?id=";

class SubPortfolioAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      portfolio: {},
      addData: {},
      pfname: "",
    };
  }

  // add symbol
  addGroupPortfolio = (pfname) => {
    // clear any errors
    this.setState({
      error: null,
      isLoading: true,
    });
    let dataSrc =
      addPortfolioURL +
      `${this.props.id}&ticket=${this.props.ticket}&mode=add&pfname=${pfname}`;
    // fetch the data
    fetch(dataSrc)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then((data) => {
        this.setAddData(data);
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };

  // set portfolio add data, returned from JSON to state
  setAddData = (data) => {
    this.setState({
      addData: data,
      isLoading: false,
    });
  };

  pfNameChanged = (e) => {
    this.setState({ pfname: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.addGroupPortfolio(this.state.pfname);
  };

  clearAddData = () => {
    this.setState({
      addData: {},
    });
  };

  renderForm() {
    if (this.props.isLoggedIn()) {
      if (this.state.isLoading) {
        return (
          <div>
            <IonSpinner color={"primary"} name={"dots"} />
            <p>Loading...</p>
          </div>
        );
      } else {
        // logged in, check status
        if (this.state.addData.status === 1) {
          // success
          return (
            <>
              <p className={"alert alert-info"}>
                Portfolio has been created successfully.
              </p>
              <p>Return to your portfolio or add another one.</p>
              <div>
                <IonButton routerLink={"/subportfolio/"} size={"small"}>
                  <IonIcon icon={chevronBackOutline} slot={"start"} /> Portfolio
                </IonButton>
                <IonButton
                  routerLink={"/subportfolio/add/"}
                  size={"small"}
                  onClick={this.clearAddData}
                >
                  Add <IonIcon icon={addOutline} slot={"end"} />
                </IonButton>
              </div>
            </>
          );
        } else if (this.state.addData.status === 0) {
          // error / warning
          return (
            <>
              <p
                className={
                  this.state.addData.warning
                    ? "alert alert-warning"
                    : "alert alert-danger"
                }
              >
                {this.state.addData.warning
                  ? "Alert: " + this.state.addData.warning
                  : "Error: " + this.state.addData.error}
              </p>
              <p>
                Please try again, or contact us for support.
                <ion-router-link href="https://www.ino.com/help/email.html">
                  {" "}
                  Contact Us
                </ion-router-link>
              </p>
              <IonButton routerLink={"/subportfolio/"} size={"small"}>
                <IonIcon icon={chevronBackOutline} slot={"start"} /> Portfolio
              </IonButton>
            </>
          );
        } else if (Object.keys(this.state.addData).length === 0) {
          // page accessed independently, no data
          return (
            <form onSubmit={this.handleSubmit} noValidate={"noValidate"}>
              <div>
                {/* <IonButton routerLink={"/subportfolio/"} size={"small"}>
                  <IonIcon icon={chevronBackOutline} slot={"start"} /> Portfolio
                </IonButton> */}
                <h2>Please enter a portfolio name.</h2>
                <IonItem className="ion-no-padding">
                  <IonInput
                    placeholder={"Portfolio name"}
                    type={"text"}
                    onIonChange={this.pfNameChanged}
                  />
                </IonItem>
                <div className="ion-text-center mt-2">
                  <IonButton
                    type={"submit"}
                    size={"medium"}
                    disabled={this.state.isLoading || this.state.pfname === ""}
                  >
                    Add Portfolio
                  </IonButton>
                </div>
                <IonRow hidden={!this.state.isLoading}>
                  <IonCol className="ion-text-center">
                    <IonSpinner color="primary" />
                  </IonCol>
                </IonRow>
              </div>
            </form>
          );
        }
      }
    } else {
      // logged out
      return (
        <>
          <p>Please log in to add a symbol.</p>
          <IonButton routerLink={"/subportfolio/"} size={"small"}>
            <IonIcon icon={chevronBackOutline} slot={"start"} /> Portfolio
          </IonButton>
        </>
      );
    }
  }
  render() {
    return <>{this.renderForm()}</>;
  }
}

export default withIonLifeCycle(SubPortfolioAdd);
