import React from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";

const headerStyle = {
  paddingBottom: "0",
};
const cardStyle = {
  margin: "0",
};

const MemberMessage = (props) => {
  return (
    <div className={["member-welcome-message  mt-3"]}>
      {props.memberMessage && props.memberMessage.display === 1 ? (
        <IonCard color={"light"} style={cardStyle}>
          <div>
            <IonCardHeader style={headerStyle}>
              <IonCardTitle>
                <h5>{props.memberMessage.title}</h5>
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <p className={"mb-2"}>{props.memberMessage.message}</p>
              <a href={props.memberMessage.link}>
                <IonButton size={"medium"}>Upgrade Membership</IonButton>
              </a>
            </IonCardContent>
          </div>
        </IonCard>
      ) : (
        ""
      )}
    </div>
  );
};

export default MemberMessage;
