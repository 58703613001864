import React from "react";
import {
  IonCol,
  IonGrid,
  IonRow,
  // useIonViewWillEnter
} from "@ionic/react";
import TriIndicator from "./TriIndicator";
import scanSlider from "../../img/smart-scan-slider.svg";
import { DATA_URL } from "../../utils/constants";

const TriangleSummary = (props) => {
  const [triangleinfo, setTriangleInfo] = React.useState({});
  const [analysis, setAnalysis] = React.useState({});
  const [symbolName, setSymbolName] = React.useState("");

  React.useEffect(() => {
    if (props.id) {
      let api =
        DATA_URL +
        `/triangledetail/?s=${props.symbol}&id=${props.id}&ticket=${props.ticket}`;

      // console.log(api);

      async function fetchData() {
        try {
          const response = await fetch(api);
          const json = await response.json();
          setTriangleInfo(json.triangleinfo);
          setAnalysis(json.analysis);
          setSymbolName(json.analysis.tick.name);
        } catch (e) {
          console.log(e);
        }
      }

      fetchData();
    }

    // reset the state when component unmounts(i.e user goes back to home page)
    return () => {
      setTriangleInfo({});
      setAnalysis({});
      setSymbolName("");
    };

    // run the useEffect when ever any param changes
  }, [props.symbol, props.id, props.ticket]);

  // useIonViewWillEnter(() => {
  //   console.log("ionViewWillEnter event fired");
  // });

  return (
    <div>
      <IonGrid>
        <IonRow>
          <IonCol>
            <div id="scale">
              <div
                id="score"
                className={`score-${analysis.justscore} ${analysis.analysisupdown}`}
              >
                <span className="operator">{analysis.plusminus}</span>
                {analysis.justscore}
              </div>
              <img
                src={scanSlider}
                width="16"
                height="16"
                alt={`Smart Scan Analysis for ${symbolName}`}
              />
              <div id="labels">
                <span>Strong Downtrend</span>
                <span className="ctr">Sideways</span>
                <span className="last">Strong Uptrend</span>
              </div>
            </div>

            <h2>Trade Triangles for {symbolName}</h2>

            <div className="trade-tri clearfix">
              <TriIndicator
                triangle={triangleinfo.monthupdown}
                symbol={props.symbol}
              />
              <span>
                The long term trend has been <b>{triangleinfo.monthupdownAC}</b>{" "}
                since {triangleinfo.monthdate} at {triangleinfo.monthprice}
              </span>
            </div>
            <div className="trade-tri clearfix">
              <TriIndicator
                triangle={triangleinfo.weekupdown}
                symbol={props.symbol}
              />
              <span>
                The intermediate term trend has been{" "}
                <b>{triangleinfo.weekupdownAC}</b> since {triangleinfo.weekdate}{" "}
                at {triangleinfo.weekprice}
              </span>
            </div>
            <div className="trade-tri clearfix last">
              <TriIndicator
                triangle={triangleinfo.dailyupdown}
                symbol={props.symbol}
              />
              <span>
                The short term trend has been{" "}
                <b>{triangleinfo.dailyupdownAC}</b> since{" "}
                {triangleinfo.dailydate}
                at {triangleinfo.dailyprice}
              </span>
            </div>

            <h2>Smart Scan Analysis for {symbolName}</h2>
            <p>{analysis.comment}</p>
            <p>{analysis.introcomment}</p>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default TriangleSummary;
