// import { IonIcon } from "@ionic/react";
import React from "react";
import PortfolioItem from "./PortfolioItem";
// import { arrowDown, arrowUp } from "ionicons/icons";

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const PortfolioTable = (props) => {
  // let { items, requestSort, sortConfig } = useSortableData(props.products);
  let { items } = useSortableData(props.products);
  const [isPercent, setIsPercent] = React.useState(true);
  items = items.filter((s) => s.pfid === props.pfid);
  // const getClassNamesFor = (name) => {
  //   if (!sortConfig) {
  //     return;
  //   }
  //   return sortConfig.key === name ? sortConfig.direction : undefined;
  // };

  const toggleChangeKey = () => {
    setIsPercent(!isPercent);
  }
  return (
    <div className={"portfolio-table subportfolio-table-rows"}>
      <table
        border="0"
        cellPadding="0"
        cellSpacing="0"
        className="table table-condensed"
      >
        <thead className="header-table">
          <tr>
            <th>Symbol</th>
            <th>Triangles</th>
            <th className="ion-text-center">Last</th>
            <th className="ion-text-center">Change</th>
          </tr>
        </thead>
        {/* <thead className="header-table">
          <tr>
            <th>
              <div className="sortable-part">
                <button type="button" onClick={() => requestSort("symbol")}>
                  Symbol
                </button>
                <IonIcon
                  icon={
                    getClassNamesFor("symbol") === "ascending"
                      ? arrowDown
                      : arrowUp
                  }
                  slot={"end"}
                />
              </div>
            </th>
            <th>
              <div className="sortable-part">
                <button type="button" onClick={() => requestSort("triangles")}>
                  Triangles
                </button>
                <IonIcon
                  icon={
                    getClassNamesFor("triangles") === "ascending"
                      ? arrowDown
                      : arrowUp
                  }
                  slot={"end"}
                />
              </div>
            </th>
            <th className="ion-text-center">
              <div className="sortable-part">
                <button type="button" onClick={() => requestSort("last")}>
                  Last
                </button>
                <IonIcon
                  icon={
                    getClassNamesFor("last") === "ascending"
                      ? arrowDown
                      : arrowUp
                  }
                  slot={"end"}
                />
              </div>
            </th>
            <th className="ion-text-center">
              <div className="sortable-part">
                <button type="button" onClick={() => requestSort("change")}>
                  Change
                </button>
                <IonIcon
                  icon={
                    getClassNamesFor("change") === "ascending"
                      ? arrowDown
                      : arrowUp
                  }
                  slot={"end"}
                />
              </div>
            </th>
          </tr>
        </thead> */}
        <tbody>
          {items.map((item) => {
            item.isPercent = isPercent;
            return (
              <PortfolioItem
                key={item.symbol}
                id={props.id}
                ticket={props.ticket}
                switchOfPricePercent={() => toggleChangeKey()}
                {...item}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PortfolioTable;
