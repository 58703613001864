import React from "react";
import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { search } from "ionicons/icons";
import { Link } from "react-router-dom";
import MemberMessage from "../components/MemberMessage/MemberMessage";
import PortfolioTriangles from "../components/PortfolioTriangles/PortfolioTriangles";
import MarketPulse from "../components/MarketPulse/MarketPulse";
import PortfolioAlerts from "../components/PortfolioAlerts/PortfolioAlerts";
import BlogIndex from "../components/TradersBlog/BlogIndex";
import FeaturedAnalysis from "../components/FeaturedAnalysis/FeaturedAnalysis";

export default function HomePage(props) {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={"start"}>
            <IonMenuButton onClick={props.toggleMenu} />
          </IonButtons>
          <IonButtons slot="end">
            <Link to={"/search"}>
              <IonIcon
                icon={search}
                className={"search-button"}
                color={"light"}
              />
            </Link>
          </IonButtons>
          <IonTitle>MarketClub</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <MarketPulse id={props.id} ticket={props.ticket} />
              <h6 className={"greeting-user"}>
                Welcome back, {props.userInfo.firstname}{" "}
                {props.userInfo.lastname}!
              </h6>

              <MemberMessage
                id={props.id}
                ticket={props.ticket}
                memberMessage={props.memberMessage}
              />

              <h2 className={"section-heading"}>Portfolio Triangles</h2>
              <PortfolioTriangles id={props.id} ticket={props.ticket} />

              <h2 className={"section-heading"}>Portfolio Alerts</h2>
              <PortfolioAlerts id={props.id} ticket={props.ticket} />

              <h2 className={"section-heading"}>Featured Analysis</h2>
              <FeaturedAnalysis
                id={props.id}
                ticket={props.ticket}
                portfolio={props.portfolio}
              />

              <h2 className={"section-heading"}>Member's Blog</h2>
              <BlogIndex id={props.id} ticket={props.ticket} numstories={3} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
