import React from "react";
import PropTypes from "prop-types";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/react";
import { withIonLifeCycle } from "@ionic/react";
import MC_logo from "../../img/MC_logo.svg";

class Logout extends React.Component {
  ionViewWillEnter() {
    this.props.logout();
  }

  render() {
    return (
      <IonPage className={"content"}>
        <img className="logoImg" src={MC_logo} alt="logo" />

        <IonContent className={"text-center"}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <p>You have been logged out.</p>
                <IonButton routerLink={"/login"} size={"medium"}>
                  Log In
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
}

Logout.propTypes = {
  logout: PropTypes.func,
};

export default withIonLifeCycle(Logout);
