import React from "react";
import PropTypes from "prop-types";
import {
  IonButton,
  IonIcon,
  IonSpinner,
  withIonLifeCycle,
  IonSelect,
  IonSelectOption,
  IonRow,
} from "@ionic/react";
import QuoteHeader from "./QuoteHeader";
import { addOutline, chevronForward } from "ionicons/icons";
import { DATA_URL } from "../../utils/constants";

const apiURL = DATA_URL + "/quote/?format=json&s=";

let dataSrc, intervalID;

class Quote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quoteData: [],
      fundamental: [],
      isLoading: true,
      error: null,
      showMenu: false,
      pfid: null,
      groupName: "",
    };
    this.getData = this.getData.bind(this);
    this.setTimer = this.setTimer.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.selectOptions = React.createRef();
  }
  ionViewWillEnter() {
    this.getData();
    this.setTimer();
    this.props.loginCheck();
  }
  ionViewWillLeave() {
    window.clearInterval(intervalID);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.symbol !== this.props.symbol) {
      this.getData();
    }
  }
  getData() {
    // clear any errors
    this.setState({
      error: null,
      pfid: null,
      groupName: "",
    });
    // fetch the data
    if (this.props.symbol !== null) {
      dataSrc = apiURL + this.props.symbol + '&id=' + this.props.id + '&ticket=' + this.props.ticket;
    }
    fetch(dataSrc)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error.");
        }
      })
      .then((data) => {
        this.setState({
          quoteData: data[`${this.props.symbol}`],
          fundamental: data[`${this.props.symbol}`].fundamental,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
        // there is an error, try again after 60 sec.
        window.clearInterval(intervalID);
        this.refreshAfterFailure();
      });
  }

  setTimer() {
    intervalID = window.setInterval(this.getData, 30000);
  }
  // there is an error, try again after 60 sec. (30 sec. timeout + 30 sec. timer)
  refreshAfterFailure() {
    // start the timer again
    window.setTimeout(this.setTimer, 30000);
  }

  showMenu(event) {
    if (this.selectOptions && this.selectOptions.current) {
      this.selectOptions.current.click();
    }
  }

  addToPortfolio = (e) => {
    const { pfid, name } = e.detail.value;
    this.setState({
      pfid: pfid,
      groupName: name,
    });
  };

  returnAddToPortfolio = () => {
    if (this.state.pfid) {
      return (
        <div className={"ion-text-end"}>
          <IonButton
            routerLink={`/portfolio/add/${this.props.symbol}/${this.state.groupName}/${this.state.pfid}`}
            size={"small"}
          >
            Add to {this.state.groupName}{" "}
            <IonIcon icon={addOutline} slot={"end"} />
          </IonButton>
        </div>
      );
    }
    return null;
  };

  returnFavoriteButton = () => {
    if (
      this.props.portfolio &&
      Object.keys(this.props.portfolio).length !== 0
    ) {
      if (
        this.props.portfolio.symbols.findIndex(
          (element) => element.symbol === this.props.symbol
        ) !== -1
      ) {
        const { pfname, pfid } = this.props.portfolio.symbols.find(
          (f) => f.symbol === this.props.symbol
        );
        return (
          <div className={"ion-text-end"}>
            <IonButton
              routerLink={`/portfolio/${pfname}/${pfid}`}
              size={"small"}
            >
              In Portfolio <IonIcon icon={chevronForward} slot={"end"} />
            </IonButton>
          </div>
        );
      }
    }
    return (
      <div className={"ion-text-end"}>
        <IonRow
          size={"small"}
          color={"success"}
          className="choose-portfolio d-inline-flex ion-align-items-center align-content-center"
        >
          <IonButton
            size={"small"}
            color={"success"}
            onClick={this.showMenu}
            className="choose-button"
          >
            Choose Portfolio
          </IonButton>
          <IonSelect
            interface={"action-sheet"}
            ref={this.selectOptions}
            onIonChange={this.addToPortfolio}
            className="pl-0 pr-3 quote-select"
          >
            {this.props.portfolio.pfgroups &&
              this.props.portfolio.pfgroups.map((g) => {
                for (const [key, value] of Object.entries(g)) {
                  return (
                    <IonSelectOption
                      key={key}
                      value={{ pfid: key, name: value }}
                    >
                      {value}
                    </IonSelectOption>
                  );
                }
                return true;
              })}
          </IonSelect>
        </IonRow>
        {this.returnAddToPortfolio()}
      </div>
    );
  };
  render() {
    if (this.state.error) {
      return <p>{this.state.error.message}</p>;
    }
    if (this.state.isLoading) {
      return (
        <div>
          <IonSpinner color={"primary"} name={"dots"} />
          <p>Loading...</p>
        </div>
      );
    }
    return (
      <div>
        <div>{this.returnFavoriteButton()}</div>
        <QuoteHeader
          last={this.state.quoteData.last}
          changeclass={this.state.quoteData.changeclass}
          netchange={this.state.quoteData.netchange}
          pctchange={this.state.quoteData.pctchange}
          feedtime={this.state.quoteData.feedtime}
          exchange={this.state.quoteData.exchange}
          name={this.state.quoteData.name}
          symbol={this.state.quoteData.symbol}
          shortsymbol={this.state.quoteData.shortsymbol}
        />
      </div>
    );
  }
}

Quote.propTypes = {
  symbol: PropTypes.string.isRequired,
  portfolio: PropTypes.object.isRequired,
};

export default withIonLifeCycle(Quote);
