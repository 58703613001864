import React from "react";
import CSSTransition from "react-transition-group/CSSTransition";

class QuoteHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priceChange: false,
      numChange: false,
      percentChange: false,
      timeChange: false,
    };
    this.handleEntered = this.handleEntered.bind(this);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.last !== prevProps.last) {
      this.setState({
        priceChange: true,
      });
    }
    if (this.props.netchange !== prevProps.netchange) {
      this.setState({
        numChange: true,
      });
    }
    if (this.props.pctchange !== prevProps.pctchange) {
      this.setState({
        percentChange: true,
      });
    }
    if (this.props.feedtime !== prevProps.feedtime) {
      this.setState({
        timeChange: true,
      });
    }
  }
  handleEntered() {
    this.setState({
      priceChange: false,
      numChange: false,
      percentChange: false,
      timeChange: false,
    });
  }
  render() {
    return (
      <>
        <h2>
          {this.props.exchange}:{this.props.shortsymbol}
          <br />
          {this.props.name}
        </h2>
        <p className="quote-price">
          <CSSTransition
            timeout={700}
            classNames="market-change"
            in={this.state.priceChange}
            onEntered={this.handleEntered}
          >
            <span>{this.props.last}</span>
          </CSSTransition>{" "}
          <span className={this.props.changeclass}>
            <CSSTransition
              timeout={700}
              classNames="market-change"
              in={this.state.numChange}
            >
              <span>{this.props.netchange}</span>
            </CSSTransition>{" "}
            (
            <CSSTransition
              timeout={700}
              classNames="market-change"
              in={this.state.percentChange}
            >
              <span>{this.props.pctchange}</span>
            </CSSTransition>
            %)
          </span>
        </p>
        <p className="quote-date">
          <CSSTransition
            timeout={700}
            classNames="market-change"
            in={this.state.timeChange}
          >
            <span>{this.props.feedtime}</span>
          </CSSTransition>
          , 15 min delay
        </p>
      </>
    );
  }
}

export default QuoteHeader;
