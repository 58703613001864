import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonBackButton,
  IonIcon,
} from "@ionic/react";
import StockChart from "../components/StockChart/StockChart";
import QuoteAboveChart from "../components/Quote/QuoteAboveChart";
import QuoteBelowChart from "../components/Quote/QuoteBelowChart";
import { Link } from "react-router-dom";
import { search } from "ionicons/icons";
import TriangleSummary from "../components/TradeTriangles/TriangleSummary";

export default function ChartPage(props) {
  const exchangeSymbol = props.match.params.symbol.split("_");
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/subportfolio" />
          </IonButtons>
          <IonButtons slot="end">
            <Link to={"/search"}>
              <IonIcon
                icon={search}
                className={"search-button"}
                color={"light"}
              />
            </Link>
          </IonButtons>
          <IonTitle>{exchangeSymbol[0]}:{exchangeSymbol[1]}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <div id="quote-above-chart-component">
                <QuoteAboveChart
                  symbol={props.match.params.symbol}
                  portfolio={props.portfolio}
                  loginCheck={props.loginCheck}
                  ticket={props.ticket}
                  id={props.id}
                />
              </div>

              <div className="hc_chart-wrapper">
                <StockChart
                  symbol={props.match.params.symbol}
                  ticket={props.ticket}
                  id={props.id}
                />
              </div>

              <TriangleSummary
                symbol={props.match.params.symbol}
                ticket={props.ticket}
                id={props.id}
              />

              <div id="quote-below-chart-component">
                <QuoteBelowChart
                  ticket={props.ticket}
                  id={props.id}
                  symbol={props.match.params.symbol}
                />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
