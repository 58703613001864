import React from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
} from "@ionic/react";
import MC_logo from "../../img/MC_logo.svg";
import Mailcheck from "mailcheck";
import { DATA_URL } from "../../utils/constants";
import { Link } from "react-router-dom";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      emailError: false,
      emailErrorMessage: null,
      emailSuggest: null,
      passwordError: false,
      errorMessage: "",
      email: "",
      password: "",
      isLoading: false,
    };
  }

  /**
   * Form field change and submit
   */
  loginFieldChange = (e) => {
    this.setState({ email: e.target.value });
  };
  passwordFieldChange = (e) => {
    this.setState({ password: e.target.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.getTicket();
  };
  /**
   * Handle email and password errors
   * @returns {JSX.Element}
   */
  handleErrors = () => {
    // loading data
    if (this.props.isLoading) {
      return (
        <div>
          <IonSpinner color={"primary"} name={"dots"} />
          <p>Loading...</p>
        </div>
      );
    }
    // general error
    if (this.state.error) {
      return (
        <>
          <IonRow className="ion-align-items-center ion-text-center">
            <IonCol className="px-5">
              <IonText color="danger">
                Error:{" "}
                {this.state.error.message
                  ? this.state.error.message
                  : this.state.errorMessage}
              </IonText>
              <p>Please try again, or contact us for support.</p>
              <div>
                <ion-router-link href="https://www.ino.com/help/email.html">
                  Contact Us
                </ion-router-link>
              </div>
            </IonCol>
          </IonRow>
        </>
      );
    }
  };

  emailValidator = (email) => {
    if (email === "") {
      this.setState({
        emailError: true,
        emailErrorMessage: "Please enter your email address",
        emailSuggest: null,
      });
    } else if (!/\S+@\S+/.test(email)) {
      this.setState({
        emailError: true,
        emailErrorMessage: "Please include an @ symbol in your email address",
        emailSuggest: null,
      });
    } else if (!/\S+\./.test(email)) {
      this.setState({
        emailError: true,
        emailErrorMessage: "Incomplete address. Please Try again",
        emailSuggest: null,
      });
    } else {
      this.setState({
        emailError: false,
        emailErrorMessage: "",
        emailSuggest: null,
      });
      return true;
    }
  };
  /**
   * Check email, password, and get ticket
   */
  getTicket = () => {
    // check for valid email address
    if (this.emailValidator(this.state.email)) {
      // check email for typos
      Mailcheck.run({
        email: this.state.email,

        // we have a typo
        suggested: (suggestion) => {
          this.setState({
            emailSuggest: suggestion.full,
          });
        },
        // email checks out
        empty: () => {
          this.setState({
            error: null,
            emailError: false,
            emailSuggest: null,
            passwordError: false,
          });

          // do we have a password?
          if (this.state.password === "") {
            this.setState({
              passwordError: true,
            });
          } else {
            this.setState({ isLoading: true });
            let api =
              DATA_URL +
              `/clubportfolio/ticket/?email=${this.state.email}&password=${this.state.password}`;

            // get user ID and ticket
            fetch(api)
              .then((response) => {
                this.setState({ isLoading: false });
                if (response.ok) {
                  return response.json();
                } else {
                  throw new Error("Error.");
                }
              })

              .then((data) => {
                this.setState({ isLoading: false });
                if (data.status === 0) {
                  this.setState({
                    error: true,
                    errorMessage: data.error,
                  });
                  this.handleErrors();
                } else {
                  // save ticket data, storage and state
                  data.email = this.state.email;
                  this.props.setLocalStorage(data);
                }
              })
              .catch((error) => {
                this.setState({
                  error,
                  isLoading: false,
                });
                this.handleErrors();
                this.setState({ isLoading: false });
              });
          }
        },
      });
    }
  };

  render() {
    return (
      <IonPage>
        <IonContent>
          <form onSubmit={this.handleSubmit} noValidate={"noValidate"}>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <div className={"text-center mt-5 mb-3"}>
                    <img className="logoImg" src={MC_logo} alt="logo" />
                  </div>

                  {this.handleErrors()}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className={"px-5 "}>
                  <IonItem className="ion-no-padding">
                    <IonInput
                      placeholder={"Your Email"}
                      inputmode={"email"}
                      type={"email"}
                      onIonChange={this.loginFieldChange}
                    />
                  </IonItem>
                  {this.state.emailError && (
                    <IonText color="danger">
                      <small>{this.state.emailErrorMessage}</small>
                    </IonText>
                  )}
                  {this.state.emailSuggest !== null && (
                    <IonText color="danger">
                      <small>Did you mean {this.state.emailSuggest}?</small>
                    </IonText>
                  )}
                  <IonItem className="ion-no-padding">
                    <IonInput
                      placeholder={"Password"}
                      inputmode={"password"}
                      type={"password"}
                      onIonChange={this.passwordFieldChange}
                    />
                  </IonItem>
                  {this.state.passwordError && (
                    <IonText color="danger">
                      <small>Please enter your password.</small>
                    </IonText>
                  )}
                </IonCol>
              </IonRow>
              <IonRow className={"ion-align-items-center"}>
                <IonCol className={"text-right forgot-pass-link my-2 px-5"}>
                  <Link to={"/login/reset-password"}>Forgot Password?</Link>
                </IonCol>
              </IonRow>
              <IonRow className={"ion-align-items-center"}>
                <IonCol className={"px-5 "}>
                  <div>
                    <IonButton
                      type={"submit"}
                      size={"medium"}
                      expand={"block"}
                      disabled={this.state.isLoading}
                    >
                      Login
                    </IonButton>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow hidden={!this.state.isLoading}>
                <IonCol className="ion-text-center">
                  <IonSpinner color="primary" />
                </IonCol>
              </IonRow>
              <IonRow className={"mt-5"}>
                <IonCol className={"text-center px-5"}>
                  <h6>Trying to join MarketClub?</h6>
                  <p>You can't sign up for MarketClub in the app. After you're a member, you can enjoy everything MarketClub has to offer.</p>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonContent>
      </IonPage>
    );
  }
}

export default Login;
