import React from "react";
import { IonSpinner, withIonLifeCycle } from "@ionic/react";
import BlogLink from "./BlogLink";
import { DATA_URL } from "../../utils/constants";

const blogPostUrl = DATA_URL + "/blogposts/?";
class BlogIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: null,
      data: [],
    };
  }

  ionViewWillEnter() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.postid !== this.props.postid) {
      this.getData();
    }
  }

  getData() {
    // clear any errors
    this.setState({
      error: null,
    });
    // fetch the data
    if (this.props.numstories !== null) {
      fetch(
        blogPostUrl +
          `ticket=${this.props.ticket}&id=${this.props.id}&membersblog=1&numstories=${this.props.numstories}`
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Error.");
          }
        })
        .then((data) => {
          this.setState({
            data: data,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            error,
            isLoading: false,
          });
          // there is an error, try again after 60 sec.
          this.refreshAfterFailure();
        });
    }
  }

  // there is an error, try again after 60 sec. (30 sec. timeout + 30 sec. timer)
  refreshAfterFailure() {
    // start the timer again
    window.setTimeout(this.setTimer, 30000);
  }

  render() {
    if (this.state.error) {
      return <p>{this.state.error.message}</p>;
    }
    if (this.state.isLoading) {
      return (
        <div>
          <IonSpinner color={"primary"} name={"dots"} />
          <p>Loading...</p>
        </div>
      );
    }
    return (
      <>
        {this.state.data.map((post) => (
          <BlogLink {...post} key={post.postid} />
        ))}
      </>
    );
  }
}

export default withIonLifeCycle(BlogIndex);
